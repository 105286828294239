<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <rent-loan-form v-if="showRentLoanForm" :toolId="this.toolId" :multiTool="this.tool.multiTool" :toolsAvailable="this.toolsAvailable" @close="closeRentLoanForm" @refreshData="fetchData"></rent-loan-form>
          </div>
        </div>
        <template v-if="enableEdit">
          <card class="stacked-form" :title="$t('tools.updateTool')" :onCancel="handleCancel">
            <form @submit.prevent="handleUpdate">
              <div class="row">
                <div class="col-md-6">
                    <label class="col-md-3 col-form-label">{{$t('common.name')}} *</label>
                    <div class="col-md-9">
                      <base-input :placeholder="$t('common.name')" ref="nameInput" v-model=tool.name> </base-input>
                    </div>

                    <label class="col-md-3 col-form-label">{{$t('common.description')}} *</label>
                    <div class="col-md-9">
                      <base-input :placeholder="$t('common.description')" ref="descriptionInput" v-model=tool.description> </base-input>
                    </div>

                    <label class="col-md-3 col-form-label">Serial</label>
                    <div class="col-md-9">
                      <base-input placeholder="Serial"  v-model=tool.sku> </base-input>
                    </div>

                    <label class="col-md-3 col-form-label">{{$t('locations.location')}} *</label>
                    <div class="col-md-9">
                      <el-select
                        ref="locationInput"
                        :class="selectClass"
                        size="large"
                        :placeholder="$t('tools.selectLocation')"
                        v-model="locationOption"
                      >
                        <el-option
                          v-for="item in locationOptions"
                          :class="selectClass"
                          :value="item.address"
                          :label="item.address"
                          :key="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <label class="col-md-3 col-form-label">{{$t('common.type')}} *</label>
                    <div v-if="toolTypeOption !== 'New Type'" class="col-md-9">
                      <el-select
                        ref="locationTypeInput"
                        :class="selectClass"
                        size="large"
                        :placeholder="$t('tools.selectType')"
                        allow-create
                        v-model="toolTypeOption"
                      >
                        <el-option
                          v-for="item in toolTypeOptions"
                          :class="selectClass"
                          :value="item.type"
                          :label="item.type"
                          :key="item.id"
                        >
                        </el-option>
                        <el-option value="New Type">{{$t('common.addNewType')}}</el-option>
                      </el-select>
                    </div>
                    <div class="new-type" v-if="toolTypeOption === 'New Type'">
                      <div class="row">
                        <base-input ref="newToolTypeInput"
                        :placeholder="$t('tools.newToolType')" v-model=newToolType> </base-input>
                      <span class="cancel-new-type" @click="cancelNewToolType">
                        <i class="tim-icons icon-simple-remove"></i>
                      </span>
                      </div>
                    </div>

                    <label class="col-md-3 col-form-label">{{$t('tools.dailyPrice')}}</label>
                    <div class="col-md-9 form-control-static">
                      <base-input :placeholder="$t('tools.dailyPrice')"  v-model=tool.dailyPrice> </base-input>
                    </div>
                    <label class="col-md-3 col-form-label">{{$t('tools.isle')}}</label>
                    <div class="col-md-9 form-control-static">
                      <base-input :placeholder="$t('tools.isle')"  v-model=tool.isle> </base-input>
                    </div>
                    <label class="col-md-3 col-form-label">{{$t('tools.shelf')}}</label>
                    <div class="col-md-9 form-control-static">
                      <base-input :placeholder="$t('tools.shelf')"  v-model=tool.shelf> </base-input>
                    </div>

                </div>
                <div class="col-md-6">
                  <div v-if="!isMobile" class="col-md-9">
                    <image-upload :showUploadButton="false" @change="handleFileUpload" :src="'data:image/png;base64,' + tool.toolImageBytes" :select-text="$t('image.selectImage')" />
                  </div>

                  <label class="col-md-3 col-form-label">{{$t('tools.brand')}}</label>
                  <div class="col-md-9">
                    <base-input :placeholder="$t('tools.brand')"  v-model=tool.brandName> </base-input>
                  </div>

                  <label class="col-md-5 col-form-label">{{$t('tools.maxReservationDays')}}</label>
                  <div class="col-md-9">
                    <base-input :placeholder="$t('tools.maxReservationDays')"  v-model=tool.maxReservationDays> </base-input>
                  </div>
                    <label class="col-md-3 col-form-label">{{$t('tools.purchaseDate')}}</label>
                    <div class="col-md-9">
                      <base-input>
                        <el-date-picker
                          type="date"
                          :placeholder="$t('tools.purchaseDate')"
                          v-model="tool.purchaseDate"
                        >
                        </el-date-picker>
                      </base-input>
                    </div>
                  <label class="col-md-5 col-form-label">{{$t('tools.purchasePrice')}}</label>
                  <div class="col-md-9">
                    <base-input :placeholder="$t('tools.purchasePrice')"  v-model=tool.purchasePrice> </base-input>
                  </div>
                  <div v-if="toolStatus === $t('toolStatus.free')" class="col-md-9">
                    <base-checkbox
                      v-model="tool.multiTool"
                    >
                      {{$t('tools.multiTool')}}
                    </base-checkbox>
                  </div>

                  <label v-if="this.tool.multiTool" class="col-md-3 col-form-label">{{$t('tools.totalCount')}}</label>
                  <div class="col-md-9">
                    <base-input v-if="this.tool.multiTool" ref="totalCountInput"  :placeholder="$t('tools.totalCount')"  v-model=tool.totalCount> </base-input>
                  </div>
                  <div v-if="isMobile" class="col-md-9">
                    <image-upload :showUploadButton="false" @change="handleFileUpload" :src="'data:image/png;base64,' + tool.toolImageBytes" :select-text="$t('image.selectImage')" />
                  </div>
                  <div v-if="enableCustomQRBanner" class="col-md-9">
                    <base-checkbox
                      v-model="showQRBannerUpload"
                    >
                      {{$t('tools.customQRBanner')}}
                    </base-checkbox>
                  </div>

                  <hr v-if="showQRBannerUpload && isMobile && enableCustomQRBanner">
                  <div v-if="showQRBannerUpload && enableCustomQRBanner" class="col-md-9">
                    <image-upload
                      :showUploadButton="false"
                      @change="handleQRBannerFileUpload"
                      :src="bannerSrc"
                      :select-text="$t('image.selectImage')"
                    />
                  </div>
                </div>
                <div class="col-sm d-flex justify-content-end">
                  <base-button class="mt-3" native-type="submit" type="standard"
                    >{{$t('common.submit')}}</base-button
                  >
                </div>
              </div>
            </form>
          </card>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-md-6">
              <card :class="cardSizeClass">
                <h2>{{ toolDisplayName }}</h2>
                <img v-if="tool.toolImageBytes" class="toolImage max-size-image" :src="'data:image/png;base64,' + tool.toolImageBytes" alt="tool alt text" />
                <h3>{{toolStatus}}
                  <div v-if="employee !== '' && !tool.multiTool" class="tool-info"><h4><strong>{{$t('tools.employee')}}: </strong> <a @click="goToUser" class="clickable-link">{{ employee }}</a></h4></div>
                  <div v-if="projectName !== '' && !tool.multiTool" class="tool-info"><h4><strong>{{$t('common.project')}}: </strong> <a @click="goToProject" class="clickable-link">{{ projectName }}</a></h4></div>
                  <div v-if="reservedTimestamp !== '' && !tool.multiTool" class="tool-info"><h4><strong>{{$t('common.date')}}: </strong> {{ reservedTimestamp }}</h4></div>
                  <div v-if="car !== '' && !tool.multiTool" class="tool-info"><h4><strong>{{$t('cars.car')}}: </strong> <a @click="goToCar" class="clickable-link">{{ car }}</a></h4></div>
                  <div v-if="externalUserLoanRent !== '' && !tool.multiTool" class="tool-info"><h4><strong>{{$t('tools.loanedRentedTo')}}: </strong> {{ externalUserLoanRent }}</h4></div>
                  <div v-if="tool.multiTool" class="tool-info"><h4><strong>{{$t('tools.totalUsed')}}: </strong> {{ toolInUse }}/{{tool.totalCount}}</h4></div>
                </h3>
                <div class="row">
                  <div v-if="this.userRole !== 'User'" class="col-md-12">
                    <base-dropdown
                      direction="up"
                      :menuOnRight=true
                      title-classes="dropdown-toggle btn btn-standard btn-block"
                      :title="$t('common.actions')"
                    >
                      <a v-if="!this.isMobile" @click="printQRCode" class="dropdown-item black">
                        <i class="tim-icons icon-cloud-download-93 black"></i>
                        {{ $t('tools.printQR') }}
                      </a>
                      <a v-if="(!this.isExternalReserved && !this.isReserved  && this.car === '' && this.toolStatus === $t('toolStatus.free')) || (this.tool.multiTool === true && this.toolStatus !== $t('toolStatus.fullyUsed'))"
                        @click="openRentLoanForm"
                        class="dropdown-item black"
                      >
                        <i class="tim-icons icon-user-run black"></i>
                        {{ $t('tools.loanRent') }}
                      </a>
                      <a v-if="this.isExternalReserved || (this.tool.multiTool && this.tool.externalToolReservations.length > 0)" @click="returnTool" class="dropdown-item green">
                        <i class="tim-icons icon-satisfied green"></i>
                        {{$t('tools.return')}}
                      </a>
                      <div v-for="option in updateToolStatusOptions" :key="option.id">
                        <a @click="confirmChangeStatus(option.status)" class="dropdown-item" :class="option.color">
                          <i v-if="option.loading" class="fa fa-spinner fa-spin"></i>
                          <i v-else :class="[option.icon, , option.color]"></i>
                          {{ option.status }}
                        </a>
                      </div>
                      <a @click="handleEdit" class="edit dropdown-item blue">
                        <i class="tim-icons icon-pencil blue"></i>
                        {{ $t('common.edit') }}
                      </a>
                      <a @click="handleDelete" class="dropdown-item red">
                        <i class="tim-icons icon-trash-simple red"></i>
                        {{ $t('common.delete') }}
                      </a>
                    </base-dropdown>
                  </div>
                </div>
              </card>
              <modal :show.sync="showUpdateReservationDaysModal" class="white-content">
                <h3 class="card-title">{{$t('common.changeDates')}}</h3>
                <div class="row">
                  <div class="col-12">
                    <div class="calendar d-flex align-items-center">
                      <div class="d-flex align-items-center" style="flex: 1;">
                        <el-date-picker
                          class="flex-grow-1 form-group"
                          type="date"
                          :placeholder="$t('common.from')"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          v-model="updatedReservationDate"
                        >
                        </el-date-picker>
                      </div>
                      <span class="mx-2">-</span>
                      <div class="d-flex align-items-center" style="flex: 1;">
                        <el-date-picker
                          class="flex-grow-1 form-group"
                          type="date"
                          :placeholder="$t('common.to')"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          v-model="updatedUnreservationDate"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <base-checkbox
                      v-model="setPriceZero"
                    >
                      {{$t('common.totalAmount')}} 0kr
                    </base-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                    <base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
                  </div>
                </div>
              </modal>

              <modal :show.sync="showReturnMultiToolModal" class="white-content">
                <card>
                  <h4 class="card-title">{{$t('tools.returnMultiTool')}}</h4>

                  <div class="col-md-12">
                    <div>

                      <el-table :data="multiToolReturnTable" >
                        <el-table-column :label="$t('common.info')" min-width="150">
                          <template slot-scope="scope">
                            <div>
                              <div><strong>{{$t('toolGroups.quantity')}}:</strong> {{ scope.row.quantity }}</div>
                              <div><strong>{{$t('tools.reservation')}}:</strong> {{ scope.row.reservationDate }}</div>
                              <div v-if="scope.row.project !== null"><strong>{{$t('common.project')}}:</strong> {{ scope.row.project }}</div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column width="50">
                          <template slot-scope="scope">
                            <el-checkbox
                              v-model="selectedMultiToolsToReturnRows[scope.row.id]"
                              @change="handleSelection(scope.row, $event)"
                            ></el-checkbox>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                      <!-- button -->
                    <div class="row">
                      <div class="col-sm d-flex justify-content-end">
                          <base-button @click="returnMultiTool" type="standard" fill>{{$t('common.confirm')}}</base-button>
                      </div>
                    </div>
                  </div>
                </card>
              </modal>

            </div>
            <div class="col-md-6">
              <card :class="cardSizeClass">
                <h2>{{$t('common.info')}}</h2>
                <div class="row">
                  <div class="col-md-8">
                    <h4><strong>{{$t('common.description')}}: </strong> {{ tool.description }}</h4>
                    <h4><strong>{{$t('tools.brand')}}: </strong>{{ tool.brandName }}</h4>
                    <h4><strong>{{$t('locations.location')}}: </strong> {{ locationOption }}</h4>
                    <h4><strong>{{$t('common.type')}}: </strong> {{ toolTypeOption }}</h4>
                    <h4><strong>{{$t('tools.dailyPrice')}}: </strong> {{ tool.dailyPrice }} Kr.</h4>
                    <h4><strong>{{$t('tools.purchaseDate')}}: </strong> {{ purchaseDateGoodFormat }}</h4>
                    <h4><strong>{{$t('tools.purchasePrice')}}: </strong> {{ tool.purchasePrice }}</h4>
                    <h4><strong>{{$t('tools.isle')}}: </strong> {{ tool.isle }}</h4>
                    <h4><strong>{{$t('tools.shelf')}}: </strong> {{ tool.shelf }}</h4>
                    <h4><strong>{{$t('tools.maxReservationDays')}}: </strong> {{ maxReservationDaysDisplayValue }}</h4>
                    <h4><strong>Serial: </strong> {{ tool.sku }}</h4>
                    <h4 v-if="tool.multiTool"><strong>{{$t('tools.totalCount')}}: </strong> {{ tool.totalCount }}</h4>

                  </div>
                  <div v-if="this.userRole !== 'User'" class="col-md-4">
                    <div class="qr-container" v-if="tool.id" @click="printQRCode">
                      <qrcode-vue
                        render-as="svg"
                        :value="qrValue"
                        :size="qrDisplaySize"
                        level="H"
                        background="#ffffff"
                        foreground="#000000"
                        ref="printqrcode"
                      >
                      </qrcode-vue>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </template>
        <div class="row" v-show="false">
          <card type="toolqr">
            <p class="card-text"></p>
            <div class="qr-print-container" v-if="tool.id">
              <div >
                <img :src=qrCodeBanner alt="app-logo" class="print-logo"/>
              </div>
              <qrcode-vue
                render-as="svg"
                :value="qrValue"
                :size="qrPrintSize"
                level="H"
                background="#ffffff"
                foreground="#000000"
                ref="printqrcode"
              >
              </qrcode-vue>
              <h3>{{ toolDisplayName }}</h3>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="row" v-if="!enableEdit" :class="{'table-min-size': !isMobile}">
      <card :title="cardTitle">
        <template v-slot:table-select>
          <div>
            <div class="btn-group btn-group-toggle" data-toggle="buttons" >
              <label v-for="(option, index) in myTablesCategories" :key="option.name" class="btn btn-sm btn-simple" :class="[{ active: activeIndex === index}, selectButtonClass]" :id="index" >
                <input type="radio" @click="initTable(index)" name="options" autocomplete="off" :checked="activeIndex === index" />
                <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                <span class="d-block d-sm-none">
                  <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                </span>
              </label>
            </div>
          </div>
        </template>
        <template v-if="tableDataReady">
          <div class="table-responsive">
            <el-base-table
              :tableData="tableData.data"
              :tableColumns="tableData.columns"
              :tableSettings="tableData.settings" />
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">{{ $t('pagination.showing') }} {{ pagination_from + 1 }} {{ $t('pagination.to') }} {{ pagination_to }} {{ $t('pagination.of') }} {{ pagination_total }} {{ $t('pagination.entries') }} </p>
            </div>
            <base-pagination
              @input="updatePagination"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination_total" >
            </base-pagination>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import api from "../../services/api";  // Import your API service
import QrcodeVue from 'qrcode.vue';  // Import QrcodeVue from qrcode.vue
import config from "@/config";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { DatePicker, Select, Option, Table, TableColumn, Checkbox } from 'element-ui';
import { ImageUpload } from 'src/components/index';
import RentLoanForm from "./RentLoanForm";
import swal from 'sweetalert2';
import { BasePagination, Modal } from 'src/components';
import ElBaseTable from "../../components/ElBaseTable.vue";
import { getCustomerConfig } from '../Settings/customerConfigHelper';


export default {
  data() {
    return {
      showQRBannerUpload: false,
      showReturnMultiToolModal: false,
      purchaseDateGoodFormat:"",
      isReserved: false,
      isExternalReserved: false,
      showRentLoanForm: false,
      toolsAvailable: 0,
      toolId: 0,
      tool: {},  // This will hold the tool data
      onCreatedTool: {}, // This will store the original tool values so we don't have to call fetch data again on cancel
      includeOwnerInformation: true,
      enableEdit: false,
      toolTypeOptions: [],
      locationOptions: [],
      updateToolStatusOptions: [
        {id: 1, status: this.$t('toolStatus.free'), loading: false, icon: 'tim-icons icon-triangle-right-17', color: 'green'},
        {id: 3, status: this.$t('toolStatus.broken'), loading: false, icon: 'tim-icons icon-simple-delete', color: 'orange'},
        {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false, icon: 'tim-icons icon-simple-remove', color: 'orange'},
      ],
      toolTypeOption: "",
      locationOption: "",
      updatedStatus:"",
      toolStatus:"",
      toolTypeId: "",
      newToolType: "",
      maxReservationDaysDisplayValue: "",
      userId: '',
      userRole: null,
      qrDisplaySize: 128,
      qrPrintSize: 256,
      isMobile: window.innerWidth <= 768,
      employee: "",
      employeeId: "",
      reservedTimestamp: "",
      projectName: "",
      projectId: "",
      car: "",
      carId: "",
      externalUserLoanRent: "",
      toolDisplayName: "",
      showUpdateReservationDaysModal: false,
      updateDays: '',
      latestUnreservedId: '',
      loading: false,
      toolInUse: 0,
      tableDataReady: false,
      tableData: {},
      multiToolReturnTable: [],
      multiToolIdsToExternalReturn:[],
      selectedMultiToolsToReturnRows: {},
      enableCustomQRBanner: false,
      cardTitle: this.$t('projects.reservationHistory'),
      activeIndex: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      updatedReservationDate: null,
      updatedUnreservationDate: null,
      setPriceZero: false
    };
  },
  watch: {
    showQRBannerUpload(newValue) {
      if (newValue === false) {
        this.tool.toolQRCodeBanner = null
      }
    }
  },
  created() {
    getCustomerConfig('EnableCustomQRBanner')
      .then(config => {
        if(config === '')
        {
          this.enableCustomQRBanner = false
        }
        else
        {
          this.enableCustomQRBanner = config
        }
      })
    this.toolId = this.$route.params.toolId;
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userId = decodedToken.UserIdentifier;
    this.userRole = decodedToken.role;
    this.fetchData()

    if(this.$route.params.editMode)
    {
      this.handleEdit();
    }
  },
  components: {
    QrcodeVue,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    'el-checkbox': Checkbox,
    Modal,
    ImageUpload,
    RentLoanForm,
    BasePagination,
    'el-base-table': ElBaseTable
  },
  computed: {
    qrCodeBanner()
    {
      if (this.tool.toolQRCodeBanner === null)
      {
        return 'img/sidebar-header-tolspor2.png'
      }
      else
      {
        return 'data:image/png;base64,' + this.tool.toolQRCodeBanner
      }


    },
    bannerSrc() {
      return this.tool.toolQRCodeBanner ? 'data:image/png;base64,' + this.tool.toolQRCodeBanner : null;
    },
    qrValue() {
      // Combine the base URL with the tool ID to create the full URL
      let qrUrl = `${config.baseUrl}/#/tool/${this.tool.id}`;
      return qrUrl;
    },
    pagination_to() {
      let highBound = this.pagination_from + this.pagination.perPage;
      if (this.pagination_total < highBound) {
        highBound = this.pagination_total;
      }
      return highBound;
    },
    pagination_from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    pagination_total() {
      return this.pagination.total;
    },
    myTablesCategories() {
      // How the table data is loaded is highly dependant on the ordering in this list, any changes here will need to be reflected in the init table method

      if(this.tool.multiTool)
      {
        return [{ name: this.$t('tools.inUse'), icon: 'tim-icons icon-single-02' },{ name: this.$t('projects.reservationHistory'), icon: 'tim-icons icon-notes' }, { name: this.$t('projects.loanRentHistory'), icon: 'tim-icons icon-paper' }];
      }
      else
      {
        return [{ name: this.$t('projects.reservationHistory'), icon: 'tim-icons icon-notes' }, { name: this.$t('projects.loanRentHistory'), icon: 'tim-icons icon-paper' }];
      }
    },
    selectClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'select-standard';
      } else {
        return 'select-primary';
      }
    },
    selectButtonClass() {
      let darkMode = localStorage.getItem('darkMode');
      // If darkMode does not exist in localStorage, default it to 'false'
      if (darkMode === null) {
        darkMode = 'false';
      }
      if (darkMode === 'false') {
        return 'btn-standard';
      } else {
        return 'btn-primary';
      }
    },
    cardSizeClass() {
      var cardClass = '';
      if (!this.isMobile) {
        if (!this.isExternalReserved && !this.isReserved && !this.tool.multiTool) {
          cardClass = 'max-size-free';
        }
        else {
          cardClass = 'max-size-reservation';
        }
      }
      return cardClass;
    },
  },
  methods: {
    changeStatus(status) {
      const clickedOption = this.updateToolStatusOptions.find(option => option.status === status);
      if (clickedOption) {
        clickedOption.loading = true;

        this.updatedStatus = status;
        this.handleUpdate().finally(() => {
          // After the operation is complete, stop loading
          clickedOption.loading = false;
        });
      }
    },
    confirmChangeStatus(status)
    {
      let confirmationTitle = ''
      let confirmationMessage = ''
      let needsUserConfirmation = false
      let removeFromCar = false
      let needsUnreserve = false
      let needsBoth = false

      if(this.car !== '')
      {
        confirmationTitle = this.$t('tools.toolIsInCar')
        confirmationMessage = this.$t('tools.toolWillBeRemovedFromCar')
        needsUserConfirmation = true
        removeFromCar = true
      }
      if(this.employee !== '')
      {
        confirmationTitle = this.$t('tools.toolIsReservedBy') + ' ' + this.employee
        confirmationMessage = this.$t('tools.toolWillBeUnreserved')
        needsUserConfirmation = true
        needsUnreserve = true
      }

      if(needsUnreserve===true && removeFromCar===true)
      {
        confirmationTitle = this.$t('tools.toolIsReservedByAndInCar') + ' ' + this.employee
        confirmationMessage = this.$t('tools.toolWillBeUnreservedAndRemovedFromCar')
        needsBoth = true
      }


      if(needsUserConfirmation === false)
      {
        this.changeStatus(status)
      }
      else
      {
        swal.fire({
          title: confirmationTitle,
          text: confirmationMessage,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: this.$t('common.continue'),
          cancelButtonText: this.$t('delete.cancel'),
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            let UnreserveOverUrl = `/Tool/Unreserve/${this.tool.id}`
            let removeFromCarUrl = `/ToolGroup/RemoveTool/`
            let removeFromCarBody = {
                toolGroupId: this.carId,
                toolId: this.tool.id
            }
            if(needsBoth === true)
            {
              api.put(UnreserveOverUrl).then(unresResponse => {
                api.put(removeFromCarUrl,removeFromCarBody).then(carResponse => {
                  this.changeStatus(status)
                })
                .catch(error => {
                  console.error('API request error:', error);
                  this.$notify({
                    message: this.$t('common.unableToUpdate'),
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                  });
                });
              })
              .catch(error => {
                console.error('API request error:', error);
                this.$notify({
                  message: this.$t('common.unableToUpdate'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                });
              });
            }
            else if(removeFromCar === true)
            {
              api.put(removeFromCarUrl,removeFromCarBody).then(response => {
                this.changeStatus(status)
              })
                .catch(error => {
                console.error('API request error:', error);
                this.$notify({
                  message: this.$t('common.unableToUpdate'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                });
              });
            }
            else if(needsUnreserve === true)
            {
              api.put(UnreserveOverUrl).then(response => {
                this.changeStatus(status)
              })
                .catch(error => {
                console.error('API request error:', error);
                this.$notify({
                  message: this.$t('common.unableToUpdate'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                });
              });
            }
          }
          else{
            return;
          }
        });
      }

    },
    cancelNewToolType(){

      if(this.toolTypeOptions.length>0){
        this.toolTypeOption = this.toolTypeOptions[0].type
      }
      else{
        this.toolTypeOption = ''
      }
    },
    async returnTool(){
      if(this.tool.multiTool)
      {
        this.showReturnMultiToolModal = true
        return;
      }
      swal.fire({
        title: this.$t('tools.areYouSureReturn'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('tools.yesReturnIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put(`/Tool/Unreserve/External/${this.toolId}`).then(response => {
            //Check if user wants to update reservation days IF this is a rent (not loan):
            this.fetchData();
            if(response.data.totalPrice && response.data.totalPrice>0) {
              this.latestUnreservedId = response.data.id
              let totalDays = this.calculateDays(response.data.reservedTimestamp, response.data.unreservedTimestamp);
              swal.fire({
                title: this.$t('tools.successfulUnreserve'),
                text: `${this.$t('tools.youHadToolFor')} ${totalDays} ${this.$t('tools.daysPrice')} ${response.data.totalPrice} Kr.`,
                icon: 'success',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('tools.acceptTotalPrice'),
                cancelButtonText: this.$t('tools.updateReservedDays'),
                buttonsStyling: false
                }).then(result => {
                if (!result.value) {
                  this.updatedReservationDate = this.formatDateResponse(response.data.reservedTimestamp);
                  this.updatedUnreservationDate = this.formatDateResponse(response.data.unreservedTimestamp);
                  this.showUpdateReservationDaysModal = true;
                }
                else{
                  swal.fire({
                    title: this.$t('tools.returned'),
                    text: `${this.$t('tools.youReturned')} ${this.tool.name}`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-success btn-fill',
                    },
                    buttonsStyling: false
                  });
                }
                });
            }
            else{

              swal.fire({
                title: this.$t('tools.returned'),
                text: `${this.$t('tools.youReturned')} ${this.tool.name}`,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                },
                buttonsStyling: false
              });
            }



        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
      });
    },
    formatDateResponse(dateString) {
      const date = new Date(dateString);
      return date.toISOString().slice(0, 10);
    },
    openRentLoanForm(){
      this.showRentLoanForm = true;
      window.scrollTo(0,0);
    },
    closeRentLoanForm(){
      this.showRentLoanForm = false;
    },
    setUpdateStatusOptions(){
      if(this.toolStatus === this.$t('toolStatus.free') || this.toolStatus === this.$t('toolStatus.inUse') ){
        this.updateToolStatusOptions = [{id: 3, status: this.$t('toolStatus.broken'), loading: false, icon: 'tim-icons icon-simple-delete', color:'orange' }, {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false, icon: 'tim-icons icon-simple-remove', color:'orange' }]
      }
      else if(this.toolStatus === this.$t('toolStatus.broken')){
        this.updateToolStatusOptions = [{id: 1, status: this.$t('toolStatus.free'), loading: false, icon: 'tim-icons icon-triangle-right-17', color:'green' }, {id: 4, status: this.$t('toolStatus.outOfUse'), loading: false, icon: 'tim-icons icon-simple-remove', color:'orange' }]
      }
      else if(this.toolStatus === this.$t('toolStatus.outOfUse')){
        this.updateToolStatusOptions = [{id: 1, status: this.$t('toolStatus.free'), loading: false, icon: 'tim-icons icon-triangle-right-17', color:'green' }, {id: 3, status: this.$t('toolStatus.broken'), loading: false, icon: 'tim-icons icon-simple-delete', color:'orange' }]
      }
      else
      {
        this.updateToolStatusOptions = []
      }
    },
    async fetchData() {
      let toolUrl = `/Tool/${this.toolId}`;
      api.get(toolUrl)
        .then(response => {
          //This is a bit of a hack to store the original value by deep copying the response.data
          this.tool = JSON.parse(JSON.stringify(response.data));
          this.purchaseDateGoodFormat = this.tool.purchaseDate.split('T')[0];
          this.toolStatus = this.getStatusTranslation(this.tool.toolStatusId)

          this.updatedStatus = this.toolStatus
          this.onCreatedTool = JSON.parse(JSON.stringify(response.data));
          this.locationOption = this.tool.location.address
          this.toolTypeOption = this.tool.toolType.type

          this.toolInUse = this.tool.inUse ? this.tool.inUse: 0
          if(this.tool.multiTool)
          {
            this.toolsAvailable = this.tool.totalCount - this.toolInUse
            this.multiToolReturnTable = this.tool.externalToolReservations
              .map(item => {
                return {
                  id: item.id,
                  quantity: item.quantity,
                  reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
                  project: item.project !== null ? item.project.name: null,
                };
              });
          }
          this.setMaxReservationDisplayValue();
          this.setToolInfo();
          this.setUpdateStatusOptions();
          this.toolDisplayName = `#${this.tool.customerToolId} ${this.tool.name}`;

          if(this.tool.toolQRCodeBanner !== null)
          {
            this.showQRBannerUpload = true
          }


          this.fetchNewData();
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      //fetch tooltypes and locations
        let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
          this.toolTypeOptions = response.data;
      })
      .catch(error => {
          console.error('API request error:', error);
      });

      let getLocationsUrl = `/Location?pageSize=100`
      api.get(getLocationsUrl)
      .then(response => {
          this.locationOptions = response.data.result;
      })
      .catch(error => {
          console.error('API request error:', error);
      });

    },
    setMaxReservationDisplayValue(){
      if(this.tool.maxReservationDays && this.tool.maxReservationDays !== 0){
        this.maxReservationDaysDisplayValue = this.tool.maxReservationDays
      }
      else{
        this.maxReservationDaysDisplayValue = '∞'
      }
    },
    setToolInfo()
    {
      if(this.tool.toolReservations.length>0)
      {
        this.employee = this.tool.toolReservations[0].user.firstName + ' ' + this.tool.toolReservations[0].user.lastName
        this.employeeId = this.tool.toolReservations[0].user.id;
        this.reservedTimestamp = this.tool.toolReservations[0].reservedTimestamp.split('T')[0]
        this.projectName = this.tool.toolReservations[0].project.name
        this.projectId = this.tool.toolReservations[0].project.id;
        this.isReserved = true;
      }
      else if(this.tool.externalToolReservations.length>0)
      {
        this.employee = this.tool.externalToolReservations[0].user.firstName + ' ' + this.tool.externalToolReservations[0].user.lastName
        this.employeeId = this.tool.externalToolReservations[0].user.id;
        this.reservedTimestamp = this.tool.externalToolReservations[0].reservedTimestamp.split('T')[0]
        this. externalUserLoanRent = this.tool.externalToolReservations[0].externalUser
        if(this.tool.externalToolReservations[0].project){
          this.projectName = this.tool.externalToolReservations[0].project.name;
          this.projectId = this.tool.externalToolReservations[0].project.id;
        }
        this.isExternalReserved = true;
      }
      else{
        this.isReserved = false
        this.isExternalReserved = false
        this.employee = ""
        this.reservedTimestamp = ""
        this.projectName = ""
        this. externalUserLoanRent = ""
      }

      if(this.tool.toolToToolGroup && this.tool.toolToToolGroup !== null){
        this.car = this.tool.toolToToolGroup.toolGroup.name;
        this.carId = this.tool.toolToToolGroup.toolGroup.id;
      }
     else{
        this.car = ''
        this.carId = ''
      }


    },
    printQRCode() {
      if (this.isMobile) {
        return;
      }
      var tmp = document.createDocumentFragment(),
      printme = document.getElementsByClassName('qr-print-container')[0].cloneNode(true);
      while(document.body.firstChild) {
          // move elements into the temporary space
          tmp.appendChild(document.body.firstChild);
      }
      // put the cloned printable thing back, and print
      document.body.appendChild(printme);
      window.print();

      while(document.body.firstChild) {
          // empty the body again (remove the clone)
          document.body.removeChild(document.body.firstChild);
      }
      // re-add the temporary fragment back into the page, restoring initial state
      document.body.appendChild(tmp);
    },
    handleEdit() {
      window.scrollTo(0, 0);
      let getToolTypesUrl = `/ToolType`
      api.get(getToolTypesUrl)
      .then(response => {
          this.toolTypeOptions = response.data;
      })
      .catch(error => {
          console.error('API request error:', error);
      });

      let getLocationsUrl = `/Location?pageSize=100`
      api.get(getLocationsUrl)
      .then(response => {
          this.locationOptions = response.data.result;
      })
      .catch(error => {
          console.error('API request error:', error);
      });
      this.enableEdit = true;

    },
    async handleUpdate() {
      if (this.validateInput()) {
        let updateUrl = `/Tool/${this.toolId}`
        if(this.newToolType !== '') {
          await api.post('/ToolType', { type: this.newToolType })
            .then(response => {
              this.tool.toolTypeId = response.data.id;
              this.toolTypeOption = response.data.type;

            })
            .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: `${this.$t('common.unableToUpdate')} ${this.tool.name}`,
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "alert",
                timeout: 2000,
              });
            });
        }
        else {
          this.tool.toolTypeId = this.toolTypeOptions.filter(op => op.type === this.toolTypeOption)[0].id;
        }
        this.tool.locationId = this.locationOptions.filter(op => op.address === this.locationOption)[0].id;

        if(this.updatedStatus !== this.toolStatus)
        {
          if(this.updateToolStatusOptions.filter(op => op.status === this.updatedStatus).length > 0)
          {
            this.tool.toolStatusId = this.updateToolStatusOptions.filter(op => op.status === this.updatedStatus)[0].id;
          }
        }
        if(this.tool.totalCount === '')
        {
          this.tool.totalCount = 0
        }

        await api.put(updateUrl, this.tool)
          .then(response => {
            this.tool = JSON.parse(JSON.stringify(response.data));
            this.purchaseDateGoodFormat = response.data.purchaseDate.split('T')[0];
            this.toolStatus = this.getStatusTranslation(response.data.toolStatusId);
            this.setUpdateStatusOptions()
            this.setMaxReservationDisplayValue();
            this.setToolInfo();
            this.$notify({
              message: `${this.tool.name} ${this.$t('common.updated')}`,
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 2000,
            });
            this.enableEdit = false;
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('common.unableToUpdate')} ${this.tool.name}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
          });
      }
    },
    validateInput() {
      let success = true;
      if (this.tool.name === '') {
        this.$refs.nameInput.focus();
        this.$notify({
          message: this.$t('errors.nameEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.description === '') {
        this.$refs.descriptionInput.focus();
        this.$notify({
          message: this.$t('errors.descriptionEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.toolImageBytes === '') {
        this.$notify({
          message: this.$t('errors.imageEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.locationOption === '') {
        this.$refs.locationTypeInput.focus();
        this.$notify({
          message: this.$t('errors.locationEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === 'New Type' && this.newToolType === '') {
        this.$refs.newToolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newToolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.multiTool && (this.tool.totalCount === 0 || this.tool.totalCount === '' || !this.checkIfNumber(this.tool.totalCount))) {
        this.$refs.totalCountInput.focus();
        this.$notify({
          message: this.$t('errors.totalCountEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.toolTypeOption === '') {
        this.$refs.toolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.toolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      else if (this.tool.inUse > this.tool.totalCount) {
        this.$refs.totalCountInput.focus();
        this.$notify({
          message: this.$t('errors.totalCountExceedsInUse'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      return success;
    },
    handleCancel() {
      this.enableEdit = false;
      this.tool = this.onCreatedTool;
    },
    handleDelete(){
      swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteTool();
        }
      });
    },
    deleteTool() {
        let deleteUrl = `/Tool/${this.toolId}`
        api.delete(deleteUrl)
          .then(response => {
            swal.fire({
              title: this.$t('delete.deleted'),
              text: `${this.$t('delete.youDeleted')} ${this.tool.name}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
              },
              buttonsStyling: false
            });
          this.goToTools();
        })
        .catch(error => {
          console.error('API request error:', error);
          swal.fire({
            title: this.$t('common.somethingWentWrong'),
            text: `${this.$t('common.unableToDelete')} ${this.tool.name}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        });
    },
    handleFileUpload(file) {
      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.tool.toolImageBytes = btoa(binary);
        };
        reader.readAsArrayBuffer(file);
      }
      else{
        this.tool.toolImageBytes = this.onCreatedTool.toolImageBytes
      }

    },
    handleQRBannerFileUpload(file)
    {
      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          bytes.forEach((byte) => binary += String.fromCharCode(byte));
          this.tool.toolQRCodeBanner = btoa(binary);
        };
        reader.readAsArrayBuffer(file);
      }
      else{
        this.tool.toolQRCodeBanner = this.onCreatedTool.toolQRCodeBanner
      }
    },
    checkIfNumber(input) {
      if (!isNaN(input)) {
        return true
      } else {
        return false
      }
    },
    goToTools() {
      this.$router.push({ name: 'Tools' });
    },
    goToCar() {
      this.$router.push({ name: 'Car', params: { toolGroupId: this.carId } });
    },
    goToProject() {
      this.$router.push({ name: 'Project', params: { projectId: this.projectId } });
    },
    goToUser() {
      if(this.userRole !== 'User' || this.userId === this.employeeId) {
        this.$router.push({ name: 'User Profile Page', params: { userId: this.employeeId } });
      }
      else {
        this.$router.push({ name: 'User', params: { userId: this.employeeId } });
      }
    },
    getStatusTranslation(statusId)
    {
       switch (statusId) {
            case 1:
              return this.$t('toolStatus.free')
            case 2:
              return this.$t('toolStatus.inUse')
            case 3:
              return this.$t('toolStatus.broken')
            case 4:
              return this.$t('toolStatus.outOfUse')
            case 5:
              return this.$t('toolStatus.loaned')
            case 6:
              return this.$t('toolStatus.rented')
            case 7:
              return this.$t('toolStatus.partlyUsed')
            case 8:
              return this.$t('toolStatus.fullyUsed')
            default:
              return 'N/A'
          }
    },
    calculateDays(date1, date2) {
      var date1_ms = new Date(date1).getTime();
      var date2_ms = new Date(date2).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
    },
    isValidDate(dateString) {
      const date = new Date(dateString);
      return date instanceof Date && !isNaN(date);
    },
    updateReservationDataIsValid() {
      if (this.updatedReservationDate === null || this.updatedReservationDate === '' || this.updatedUnreservationDate === null || this.updatedUnreservationDate === '') {
        this.$notify({
          message: this.$t('errors.datesEmptyOrIncorrect'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
        return false;
      }

      if (!this.isValidDate(this.updatedReservationDate) || !this.isValidDate(this.updatedUnreservationDate)) {
        this.$notify({
          message: this.$t('errors.datesEmptyOrIncorrect'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
        return false;
      }

      if (new Date(this.updatedReservationDate) > new Date(this.updatedUnreservationDate)) {
        this.$notify({
          message: this.$t('errors.startDateBiggerThanEndDate'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
        return false;
      }
      return true;
    },
    updateReservationDays(){
      if (!this.updateReservationDataIsValid()) {
        return;
      }
      var requestBody = this.setPriceZero ?
          {updatedReservedTimestamp: this.updatedReservationDate, updatedUnreservedTimestamp: this.updatedUnreservationDate, totalPrice: 0} :
          {updatedReservedTimestamp: this.updatedReservationDate, updatedUnreservedTimestamp: this.updatedUnreservationDate};
      api.put(`/Tool/Reservation/External/${this.latestUnreservedId}`, requestBody).then(response => {
        this.showUpdateReservationDaysModal = false;
        this.updatedReservationDate = null;
        this.updatedUnreservationDate = null;
        this.setPriceZero = false;
        swal.fire({
          title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
          },
          buttonsStyling: false
        });
      })
      .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    initTable(index) {
      this.activeIndex = index;
      this.tableDataReady = false;
      this.fetchNewData();
    },
    updatePagination(item) {
      this.pagination.currentPage = item;
      this.fetchNewData();
    },
    fetchNewData() {
      if(this.tool.multiTool)
      {
        if (this.activeIndex === 0) {
          this.cardTitle = this.$t('tools.inUse');
          this.activeEntity = 'ToolsInUse';
          this.fetchMultiToolInUseTable();
        }
        else if (this.activeIndex === 1) {
          this.cardTitle = this.$t('projects.reservationHistory');
          this.activeEntity = 'ToolReservation';
          this.fetchHistoryTableData();
        }
        else {
          this.cardTitle = this.$t('projects.loanRentHistory');
          this.activeEntity = 'ExternalReservation';
          this.fetchLoanRentHistoryTableData();
        }
      }
      else
      {
        if (this.activeIndex === 0) {
          this.cardTitle = this.$t('projects.reservationHistory');
          this.activeEntity = 'ToolReservation';
          this.fetchHistoryTableData();
        }
        else {
          this.cardTitle = this.$t('projects.loanRentHistory');
          this.activeEntity = 'ExternalReservation';
          this.fetchLoanRentHistoryTableData();
        }
      }

    },
    async returnMultiTool()
    {
      if(this.multiToolIdsToExternalReturn.length === 0)
      {
        this.$notify({
          message: this.$t('errors.nothingSelected'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
        return
      }
      if(this.multiToolIdsToExternalReturn.length > 0)
      {
        let ids = this.multiToolIdsToExternalReturn.join(',');
        await api.put(`/Tool/UnreserveByIds/External`,{ids: ids});
      }

      this.fetchData();

      this.showReturnMultiToolModal = false;

      this.$notify({
        message: this.$t('tools.toolReturned'),
        icon: "tim-icons icon-check-2",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
        timeout: 1500,
      });
    },
    handleSelection(row, checked) {
      if (checked)
      {
        if (!this.multiToolIdsToExternalReturn.includes(row.id))
        {
          this.multiToolIdsToExternalReturn.push(row.id);
        }
      }
      else
      {
        this.multiToolIdsToExternalReturn = this.multiToolIdsToExternalReturn.filter(item => item !== row.id);
      }
    },
    async fetchMultiToolInUseTable()
    {
      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&toolId=${this.toolId}&unreserved=false&orderByReservedTimestamp=false&orderByDescending=true`;
      this.tableData = {
        title: this.$t('projects.reservationHistory'),
        columns: [
          { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
          { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
          { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
          { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 120 },
          { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'tools.reservedBy': 'reserved by', 'common.project': 'project', 'tools.reserved': 'reserved','tools.quantity': 'quantity'}},
        ],
        data: [],
      }
      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          return {
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'reserved by': item.user.firstName + " " + item.user.lastName,
            id: item.id,
            project: item.project.name,
            quantity: item.quantity

          };
        });
        this.tableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },
    async fetchHistoryTableData() {
      let toolReserveUrl = `/Tool/Reservations?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&toolId=${this.toolId}&unreserved=true&orderByReservedTimestamp=false&orderByDescending=true`;
      if(this.userRole === 'User')
      {
        this.tableData = {
          title: this.$t('projects.reservationHistory'),
          columns: [
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.project': 'project', 'tools.reserved': 'reserved','tools.returned': 'returned', 'common.totalAmount':'totalPrice'}},
          ],
          data: [],
        }
      }
      else
      {
        this.tableData = {
          title: this.$t('projects.reservationHistory'),
          columns: [
            { prop: 'reserved by', label: this.$t('tools.reservedBy'), minWidth: 200},
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'tools.reservedBy': 'reserved by', 'common.project': 'project', 'tools.reserved': 'reserved','tools.returned': 'returned', 'common.totalAmount':'totalPrice'}},
          ],
          data: [],
        }
      }

      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          return {
            'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            'returned': new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            'reserved by': this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName,
            totalPrice: item.totalPrice,
            id: item.id,
            project: item.project.name

          };
        });
        this.tableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    },
    async fetchLoanRentHistoryTableData() {
      if(this.userRole === 'User')
      {
        this.tableData = {
          title: this.$t('projects.loanRentHistory'),
          columns: [
            { prop: 'externalReservationType', label: this.$t('toolGroups.reservationType'), minWidth: 200 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 80 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 80 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'toolGroups.reservationType':'externalReservationType', 'tools.loanedRentedDate': 'reserved', 'tools.returned':'returned', 'common.totalAmount':'totalPrice', 'common.project':'project'}},
          ],
          data: [],

        }
      }
      else
      {
        this.tableData = {
          title: this.$t('projects.loanRentHistory'),
          columns: [
            { prop: 'externalReservationType', label: this.$t('toolGroups.reservationType'), minWidth: 200 },
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 80 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 80 },
            { prop: 'loanedRentedBy', label: this.$t('tools.loanedRentedBy'), minWidth: 200 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 120 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'toolGroups.reservationType':'externalReservationType', 'tools.loanedRentedDate': 'reserved', 'tools.returned':'returned', 'common.totalAmount':'totalPrice', 'tools.loanedRentedBy': 'loanedRentedBy', 'common.project':'project'}},
          ],
          data: [],

        }
      }

      let toolReserveUrl = `/Tool/Reservations/External?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&toolId=${this.toolId}&unreserved=true&orderByReservedTimestamp=false&orderByDescending=true`;
      try {
        const response = await api.get(toolReserveUrl);
        this.pagination.total = response.data.totalItems;
        this.tableData.data = response.data.result.map(item => {
          let reservationType = this.$t('tools.loaned') + " - " + item.externalUser;
          if(item.rented)
          {
            reservationType = this.$t('tools.rented') + " - " + item.externalUser;
          }

          return {
            id: item.id,
            toolId: item.toolId,
            image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes, //should be image
            tool: item.tool.name,
            customerToolId: '#' + item.tool.customerToolId,
            reserved: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            returned: new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
            externalReservationType: reservationType,
            loanedRentedBy: this.userRole === 'User' ? undefined : item.user.firstName + " " + item.user.lastName,
            totalPrice: item.totalPrice,
            project: item.project !== null ? item.project.name : ''
          };
        });
        this.tableDataReady = true;
      }
      catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
    }
  }
};
</script>
<style scoped>
.tool-info{
  font-size: 1.0624999875rem;
  color: #1d253b;
}
.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancel-new-type {
  padding-left: 10px; /* Adjust as needed */
  padding-top: 10px;  /* Adjust as needed */
}
.new-type {
  padding-left: 30px; /* Adjust as needed */
}
@media print {
  h3 {
    color: black !important; /* Force the text color to be black when printed */
    font-weight: bold !important; /* Force the font weight to be bold when printed */
  }
  .qr-print-container {
    width: 100%;
    height: 50vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .qrcode-vue {
    width: 100%;
    height: auto;
  }
}
.print-logo
  {
    width: 256px;
    padding-bottom: 10px;
  }
.clickable-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.clickable-link:hover {
  color: #0056b3;
}

.black {
  color: black;
}

.blue {
  color: blue;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.green {
  color: green;
}

.table-min-size {
  min-height: 501px;;
}

.max-size-free {
  max-height: 520px;
  min-height: 520px;
}

.max-size-reservation {
  max-height: 600px;
  min-height: 600px;
}

.max-size-image {
  max-height: 300px;
}
</style>
