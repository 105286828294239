<template>
<div class="row-md-12">
    <div class="row mt-5">
        <div class="col-md-12">
        <create-edit-location-form v-if="showCreateLocationForm"
        @close="closeCreateLocationForm"
         @refreshData="fetchData"
          :editMode="this.editMode"
          :addressProp="this.address"
          :latitudeProp="String(this.latitude)"
          :longitudeProp="String(this.longitude)"
          :selectedLocationTypeProp="this.selectedLocationType"
          :requirePresenceForToolReturnProp="this.requirePresenceForToolReturn"
          :radiusProp="String(this.radius)"
          :editingLocationId="this.editingLocationId"></create-edit-location-form>
        </div>
      <div class="col-12">
        <card card-body-classes="table-full-width" :title="$t('locations.locations')" :onAddObject="openCreateLocationForm">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @keyup.native="search"
                  @clear="handleClearSearch"
                >
                </el-input>
              </base-input>

            </div>

            <!-- Desktop Table -->
            <el-table v-if="!isMobile" :data="locations">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>

              <el-table-column :label="$t('locations.coordinates')" min-width="150">
                <template slot-scope="scope">
                  <div>
                    <div><strong>{{ $t('locations.latitude') }}:</strong> {{ scope.row.latitude }}</div>
                    <div><strong>{{ $t('locations.longitude') }}:</strong> {{ scope.row.longitude }}</div>
                    <div v-if="scope.row.radius !== null"><strong>{{ $t('locations.radius') }}:</strong> {{ scope.row.radius }}</div>

                  </div>
                </template>
              </el-table-column>

              <el-table-column :label="$t('locations.requirePresenceForToolReturn')" min-width="150">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.requirePresenceForToolReturn ? $t('common.yes') : $t('common.no')}}
                  </div>
                </template>
              </el-table-column>

              <el-table-column :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native.stop="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


            <!-- Mobile Table -->
            <el-table v-else :data="locations">
              <el-table-column :label="$t('common.info')" min-width="200">
                <template slot-scope="scope">
                  <div>
                    <div><strong>{{ $t('locations.address') }}:</strong> {{ scope.row.address }}</div>
                    <div><strong>{{ $t('common.type') }}:</strong> {{ scope.row.type }}</div>
                    <div><strong>{{ $t('locations.requirePresenceForToolReturn') }}:</strong> {{ scope.row.requirePresenceForToolReturn ? $t('common.yes') : $t('common.no')}}</div>

                  </div>
                </template>
              </el-table-column>

              <el-table-column :min-width="135" align="right" label="">
                <div slot-scope="props">
                  <base-button
                    @click.native.stop="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>


          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t('pagination.showing') }} {{ from + 1 }} {{ $t('pagination.to') }} {{ to }} {{ $t('pagination.of') }} {{ total }} {{ $t('pagination.entries') }}
              </p>
            </div>
            <base-pagination
            @input="updatePage"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>

    </div>
</div>
</template>
<script>
import api from "../../services/api"
import CreateEditLocationForm from "./CreateEditLocationForm";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import swal from 'sweetalert2';
import { getCustomerConfig } from '../Settings/customerConfigHelper';



export default {
components: {
    CreateEditLocationForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
},
data() {
    return {
      showCreateLocationForm: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns: [
        {
          prop: 'address',
          label: this.$t('locations.address'),
          minWidth: 150
        },
        {
          prop: 'type',
          label: this.$t('common.type'),
          minWidth: 90
        }
      ],
      searchQuery: '',
      locations: [],
      isMobile: window.innerWidth <= 768,

      editMode: false,
      selectedLocationType: '',
      longitude: '',
      latitude: '',
      address: '',
      radius: '',
      requirePresenceForToolReturn: false,
      editingLocationId: 0
    };
},
computed: {
  to() {
    let highBound = this.from + this.pagination.perPage;
    if (this.total < highBound) {
      highBound = this.total;
    }
    return highBound;
  },
  from() {
    return this.pagination.perPage * (this.pagination.currentPage - 1);
  },
  total() {
    return this.pagination.total;
  }
},
methods: {
  search(event)
  {
    this.pagination.currentPage = 1;
    const queryLength = this.searchQuery.length;
    if (event.key === 'Backspace' || event.key === 'Enter') {
      this.fetchData();
    }
    else {
      if (queryLength > 1) {
        if (queryLength % 2 === 0) {
          this.fetchData();
        }
      }
    }
  },
  handleClearSearch()
  {
    Cookies.remove('locationsPage');
    this.pagination.currentPage = 1
    this.fetchData();
  },
  updatePage(item){
    this.pagination.currentPage = item;
    Cookies.set('locationsPage', this.pagination.currentPage, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
    this.fetchData()
  },
  openCreateLocationForm()
  {
    this.showCreateLocationForm = true;
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },
  closeCreateLocationForm()
  {
      this.showCreateLocationForm = false;
      this.address = '';
      this.longitude = '';
      this.latitude = '';
      this.selectedLocationType = '';
      this.requirePresenceForToolReturn = false;
      this.editingLocationId = 0
      this.radius = '';
      this.editMode = false;
  },
  deleteLocation(row)
  {
    let deleteUrl = `/Location/` + row.id
    api.delete(deleteUrl)
    .then(response => {
      this.locations = this.locations.filter(loc => loc.id !== row.id);
    })
    .catch(error => {
      console.error('API request error:', error);
    });
  },
  fetchData() {
      let getLocationsUrl = ''
      if(this.searchQuery === '')
      {
          Cookies.remove('locationsSearchQuery');
          getLocationsUrl = `/Location?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}`
      }
      else
      {
         //Store searchQuery as cookie - 5 minute expiry time
        Cookies.set('locationsSearchQuery', this.searchQuery, { expires: new Date(new Date().getTime() + 1 * 60 * 1000) });
        getLocationsUrl = `/Location?currentPage=${this.pagination.currentPage}&pageSize=${this.pagination.perPage}&address=${this.searchQuery}`
      }
      api.get(getLocationsUrl)
      .then(response => {
        this.pagination.total = response.data.totalItems;
        this.locations = response.data.result.map(item => {
          return {
            id: item.id,
            address: item.address,
            type: item.locationType.type,
            latitude: item.latitude,
            longitude: item.longitude,
            radius: item.radiusMeters,
            requirePresenceForToolReturn: item.requirePresenceForToolReturn
          };
        });
      })
      .catch(error => {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      });
  },

    handleDelete(index, row) {
      swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('locations.areYouSureDelete'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteLocation(row);
          swal.fire({
            title: this.$t('delete.deleted'),
            text: `${this.$t('delete.youDeleted')} ${row.address}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        }
      });
    },
    handleEdit(index, row)
    {
      this.editMode = true;
      this.address = row.address;
      this.longitude = row.longitude;
      this.latitude = row.latitude;
      this.selectedLocationType = row.type;
      this.editingLocationId = row.id;
      this.requirePresenceForToolReturn = row.requirePresenceForToolReturn;
      this.radius = row.radius
      if(this.latitude === null){
        this.latitude = ''
      }
      if(this.longitude === null){
        this.longitude = ''
      }
      if(this.radius === null){
        this.radius = ''
      }
      this.openCreateLocationForm()
    },
  },
  created() {

    // Check if the cookie exists
    const savedSearchQuery = Cookies.get('locationsSearchQuery');
    if (savedSearchQuery) {
      this.searchQuery = savedSearchQuery;
    } else {
      this.searchQuery = '';
    }

    const savedPage = Cookies.get('locationsPage');
    if (savedPage) {
      this.pagination.currentPage = Number(savedPage);
    } else {
      this.pagination.currentPage = 1
    }
    getCustomerConfig('DefaultPageSize')
    .then(config => {
      if(config === '')
      {
        this.pagination.perPage = 10
      }
      else
      {
        this.pagination.perPage = config
      }
      this.fetchData();
    })

  },
};
</script>
<style></style>
