<template>
  <div class="customer-settings">
    <div class="row">
      <div class="col-md-6">
        <card :title="$t('customerSettings.companyPhoto')">
          <div class="image-containercustomersettings">
            <image-upload
              class="col-md-6 ml-auto mr-auto"
              @change="onImageChange"
              @upload="onUpload"
              :src="'data:image/png;base64,' + customer.logoBytes"
              :base64Image="customer.logoBytes"
              :select-text="$t('image.selectImage')"/>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card :title="$t('customerSettings.generalSettings')" class="stacked-form">
           <div class="row">
            <div class="col-md-12">
              <base-input :label="$t('customerSettings.defaultPageSize')" :placeholder="$t('customerSettings.defaultPageSize')" ref="defaultPageSizeInput" v-model=defaultPageSize> </base-input>
              <hr>
              <base-checkbox
                v-model="disableCars"
              >
                {{$t('customerSettings.disableCars')}}
              </base-checkbox>
              <base-checkbox
                v-model="allowAllUsersToReturn"
              >
                {{$t('customerSettings.allowAllUsersToReturn')}}
              </base-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm d-flex justify-content-end">
              <base-button class="mt-3" native-type="submit" type="standard" @click="submitGeneralSettings"
                >{{$t('common.submit')}}</base-button
              >
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-if="this.erp !== '' && this.erp != 'Test'">
      <div class="col-md-12">
        <card :title="$t('customerSettings.erpSettings')" :customMethodImage="getERPImage">
           <div class="row">
            <div class="col-md-6">
              <base-input v-if="erp === 'DK'" type="password" :label="$t('customerSettings.DKApiKey')" :placeholder="$t('customerSettings.DKApiKey')" ref="DKApiKeyInput" v-model=DKApiKey> </base-input>
              <base-input v-if="erp === 'Tímon'" :label="$t('customerSettings.timonUsername')" :placeholder="$t('customerSettings.timonUsername')" ref="timonUsernameInput" v-model=timonUsername> </base-input>
              <base-input v-if="erp === 'Tímon'" type="password" :label="$t('customerSettings.timonPassword')" :placeholder="$t('customerSettings.timonPassword')" ref="timonPasswordInput" v-model=timonPassword> </base-input>
              <base-input v-if="erp === 'Stólpi'" :label="$t('customerSettings.stolpiUsername')" :placeholder="$t('customerSettings.stolpiUsername')" ref="stolpiUsernameInput" v-model=stolpiUsername> </base-input>

            </div>
            <div class="col-md-6">
              <base-input v-if="erp === 'DK'" type="password" :label="$t('customerSettings.DKWebhookSecret')" :placeholder="$t('customerSettings.DKWebhookSecret')" ref="DKWebhookSecretInput" v-model=DKWebhookSecret> </base-input>
              <base-input v-if="erp === 'Tímon'" :label="$t('customerSettings.timonUrl')" :placeholder="$t('customerSettings.timonUrl')" ref="timonUrlInput" v-model=timonUrl> </base-input>
              <base-input v-if="erp === 'Stólpi'" type="password" :label="$t('customerSettings.stolpiPassword')" :placeholder="$t('customerSettings.stolpiPassword')" ref="stolpiPasswordInput" v-model=stolpiPassword> </base-input>
            </div>
            <div class="col-sm d-flex justify-content-end">
              <base-button @click="submitERPSettings" class="mt-3" native-type="submit" type="standard"
                >{{$t('common.submit')}}</base-button
              >
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-if="userRole === 'SuperAdmin'">
      <div class="col-md-12">
        <card :title="$t('customerSettings.superAdminSettings')">
          <div class="row">
            <div class="col-md-6">
              <base-checkbox
                v-model="enableCustomQRBanner"
              >
                {{$t('customerSettings.enableCustomQRBanner')}}
              </base-checkbox>
              <base-checkbox
                v-model="enableProjectCreation"
              >
                {{$t('customerSettings.enableProjectCreation')}}
              </base-checkbox>
            </div>

              <div class="col-sm d-flex justify-content-end">
                <base-button @click="submitSuperAdminSettings" class="mt-3" native-type="submit" type="standard"
                  >{{$t('common.submit')}}</base-button
                >
              </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageUpload, Card, BaseInput, BaseButton, BaseCheckbox } from 'src/components/index';
import { Table, TableColumn } from 'element-ui';
import api from "../../services/api";  // Import your API service
import swal from 'sweetalert2';
import { getCustomerConfigTypeId, getCustomerConfig, loadCustomerConfigs } from './customerConfigHelper';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

export default {
  components: {
    ImageUpload,
    Card,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      images: {
        regular: null,
      },
      customer: {
        name: '',
        logoBytes: null
      },

      enableCustomQRBanner: false,
      enableProjectCreation: false,
      disableCars: false,
      allowAllUsersToReturn: false,
      timonUsername: '',
      timonPassword: '',
      originalTimonPassword: '',
      timonUrl: '',
      DKApiKey: '',
      DKWebhookSecret: '',
      OriginalDKWebhookSecret: '',
      OriginalDKApiKey: '',
      defaultPageSize: 10,
      stolpiUsername: '',
      stolpiPassword: '',
      originalStolpiPassword: '',
      erp: '',
      userRole: ''
    };
  },
  computed: {
    getERPImage()
    {
      switch (this.erp) {
        case 'DK':
          return 'img/dk-logo2.png';
        case 'Tímon':
          return 'img/timon.png';
        case 'Stólpi':
          return 'img/stolpi.png';
        default:
          return ''
      }
    },
  },
  methods: {
    checkIfNumber(input) {
      if (!isNaN(input)) {
        return true
      } else {
        return false
      }
    },
    validateGeneralSettings()
    {
      let errorMessage = '';
      if (this.defaultPageSize === '' || !this.checkIfNumber(this.defaultPageSize))
      {
        errorMessage = this.$t('customerSettings.defaultPageSizeNotNumber')
      }
      if(errorMessage !== '')
      {
        this.$notify({
            message: errorMessage,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        return false
      }

      return true
    },
    submitSuperAdminSettings()
    {
      let url = "/Customers/CustomerConfigurations";  // Your POST endpoint
      let body = {
        configurations: [
          {
            customerConfigurationTypeId: getCustomerConfigTypeId('EnableCustomQRBanner'),
            value: String(this.enableCustomQRBanner)
          },
          {
            customerConfigurationTypeId: getCustomerConfigTypeId('EnableProjectCreation'),
            value: String(this.enableProjectCreation)
          }
        ]
      };
      api.post(url, body)
        .then(response => {
          loadCustomerConfigs()
          this.$notify({
            message: `${this.$t('customerSettings.configurationsUpdated')}`,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    submitERPSettings()
    {
      let body = {
        configurations: [
        ]
      };
      switch (this.erp) {
        case 'DK':
          if(this.DKApiKey !== this.OriginalDKApiKey)
          {
            body.configurations.push({
              customerConfigurationTypeId: getCustomerConfigTypeId('DKApiKey'),
              value: this.DKApiKey,
            })
          }
          if(this.DKWebhookSecret !== this.OriginalDKWebhookSecret)
          {
            body.configurations.push({
              customerConfigurationTypeId: getCustomerConfigTypeId('DKWebhookSecret'),
              value: this.DKWebhookSecret,
            })
          }
          break;
        case 'Tímon':
          if(this.timonPassword !== this.originalTimonPassword)
          {
            body.configurations.push({
              customerConfigurationTypeId: getCustomerConfigTypeId('TimonPassword'),
              value: this.timonPassword,
            })
          }
          body.configurations.push({
            customerConfigurationTypeId: getCustomerConfigTypeId('TimonUsername'),
            value: this.timonUsername,
          })
          body.configurations.push({
              customerConfigurationTypeId: getCustomerConfigTypeId('TimonUrl'),
              value: this.timonUrl,
            })
          break;
        case 'Stólpi':
          if(this.stolpiPassword !== this.originalStolpiPassword)
          {
            body.configurations.push({
              customerConfigurationTypeId: getCustomerConfigTypeId('StolpiPassword'),
              value: this.stolpiPassword,
            })
          }
          body.configurations.push({
            customerConfigurationTypeId: getCustomerConfigTypeId('StolpiUsername'),
            value: this.stolpiUsername,
          })
          break;
        default:
          this.$notify({
            message: this.$t('customerSettings.unknownERP') + ' - ' + this.erp,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          return;
      }

      if(body.configurations.length <= 0)
      {
        this.$notify({
          message: this.$t('customerSettings.nothingToUpdate'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
          timeout: 2000,
        });
        return;
      }
      let url = "/Customers/CustomerConfigurations";  // Your POST endpoint
      api.post(url, body)
        .then(response => {
          loadCustomerConfigs().then(x => {
            this.getAllCustomerConfigs()
            this.$notify({
              message: `${this.$t('customerSettings.configurationsUpdated')}`,
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 2000,
            });
          })
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    submitGeneralSettings()
    {
      if(!this.validateGeneralSettings())
      {
        return;
      }
      let url = "/Customers/CustomerConfigurations";  // Your POST endpoint
      let body = {
        configurations: [
          {
            customerConfigurationTypeId: getCustomerConfigTypeId('DefaultPageSize'),
            value: String(this.defaultPageSize),
          },
          {
            customerConfigurationTypeId: getCustomerConfigTypeId('DisableCars'),
            value: String(this.disableCars),
          },
          {
            customerConfigurationTypeId: getCustomerConfigTypeId('AllowAllUsersToReturn'),
            value: String(this.allowAllUsersToReturn),
          },
        ]
      };
      api.post(url, body)
        .then(response => {
          loadCustomerConfigs()
          this.$notify({
            message: `${this.$t('customerSettings.configurationsUpdated')}`,
            icon: "tim-icons icon-check-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 2000,
          });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    handleDelete(key){
         swal.fire({
        title: this.$t('delete.areYouSure'),
        text: this.$t('delete.noRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('delete.yesDeleteIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteCustomerSetting(key);
          swal.fire({
            title: this.$t('delete.deleted'),
            text: `${this.$t('delete.youDeleted')} ${key}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        }
      });
    },
    deleteCustomerSetting(key){
      let deleteUrl = `/Customers/CustomerSettings/` + key
      api.delete(deleteUrl)
      .then(response => {
          this.tableData = this.tableData.filter(s => s.key !== key);
      })
          .catch(error => {
          console.error('API request error:', error);
      });
    },
    fetchData() {
      let url = "Customers/CustomerSettings";  // Replace with your API endpoint
      api.get(url)
        .then(response => {
          this.tableData = response.data.map(item => {
            return {
              key: item.key,
              value: item.value,
              encrypted: item.encrypted
            };
          });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        });
    },
    fetchCustomer() {
      let url = "Customers/Me";  // Replace with your API endpoint
      api.get(url)
        .then(response => {
          this.erp = response.data.value.erpName
          this.customer.name = response.data.value.name;
          if (response.data.value.logoBytes) {
            this.customer.logoBytes = response.data.value.logoBytes;
            // Convert base64 to Blob
            let byteCharacters = atob(this.customer.logoBytes);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: "image/png"});
            // Create URL representing the image
            this.images.regular = URL.createObjectURL(blob);
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
    },
    onUpload() {
      let reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result.replace(/^data:.+;base64,/, '');
        let url = "Customers/Update/Logo";  // Your POST endpoint
        let body = {
          logoBytes: base64String
        };
        api.put(url, body)
          .then(response => {
            // Handle successful upload
            this.$notify({
              message: this.$t('customerSettings.logoUpdated'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 2000,
            });
            this.fetchCustomer();
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToUpdate'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
      };
      reader.readAsDataURL(this.images.regular);
    },
    getAllCustomerConfigs()
    {
      getCustomerConfig('EnableCustomQRBanner')
        .then(config => {
          if(config === '')
          {
            this.enableCustomQRBanner = false
          }
          else
          {
            this.enableCustomQRBanner = config
          }
        })
      getCustomerConfig('EnableProjectCreation')
        .then(config => {
          if(config === '')
          {
            this.enableProjectCreation = false
          }
          else
          {
            this.enableProjectCreation = config
          }
        })
      getCustomerConfig('DisableCars')
        .then(config => {
          if(config === '')
          {
            this.disableCars = false
          }
          else
          {
            this.disableCars = config
          }
        })
      getCustomerConfig('AllowAllUsersToReturn')
        .then(config => {
          if(config === '')
          {
            this.allowAllUsersToReturn = false
          }
          else
          {
            this.allowAllUsersToReturn = config
          }
        })
      getCustomerConfig('TimonUsername')
        .then(config => {
          this.timonUsername = config
        })
      getCustomerConfig('TimonPassword')
        .then(config => {
          this.timonPassword = config
          this.originalTimonPassword = config
        })
      getCustomerConfig('TimonUrl')
        .then(config => {
          this.timonUrl = config
        })
      getCustomerConfig('DKApiKey')
        .then(config => {
          this.DKApiKey = config
          this.OriginalDKApiKey = config
        })
      getCustomerConfig('DKWebhookSecret')
        .then(config => {
          this.DKWebhookSecret = config
          this.OriginalDKWebhookSecret = config
        })
      getCustomerConfig('DefaultPageSize')
        .then(config => {
          this.defaultPageSize = config
        })
      getCustomerConfig('StolpiUsername')
        .then(config => {
          this.stolpiUsername = config
        })
      getCustomerConfig('StolpiPassword')
        .then(config => {
          this.stolpiPassword = config
          this.originalStolpiPassword = config
        })
    }
  },
  created() {
    const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role

    this.getAllCustomerConfigs();
    this.fetchCustomer();  // Fetch customer data when the component is created
  }
};
</script>

<style>
.image-upload-page .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.image-containercustomersettings {
  display: flex;
  justify-content: space-between;
}

.image-containercustomersettings > img,
.image-containercustomersettings > image-upload {
  width: 49%;  /* Adjust as needed */
}
</style>
