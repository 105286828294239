<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="cardTitle">
          <template v-slot:header-extra v-if="isMobile">
            <div class="unreserve-tools">
              <QrScanner class="qr-scanner-unreserve" @decodedReserve="startReserve" @decodedReturn="unreserve" />
            </div>
          </template>
          <modal :show.sync="showReturnMultiToolModal" class="white-content">
            <card>
              <h4 class="card-title">{{$t('tools.returnMultiTool')}} - {{multiToolReturningName}}</h4>

              <div class="col-md-12">
                <div>

                  <el-table :data="multiToolReturnTable" >
                    <el-table-column :label="$t('common.info')" min-width="150">
                      <template slot-scope="scope">
                        <div>
                          <div><strong>{{$t('toolGroups.quantity')}}:</strong> {{ scope.row.quantity }}</div>
                          <div><strong>{{$t('tools.reservation')}}:</strong> {{ scope.row.reservationDate }}</div>
                          <div><strong>{{$t('common.project')}}:</strong> {{ scope.row.project }}</div>
                          <div><strong>{{$t('common.type')}}:</strong> {{ scope.row.type }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="50">
                      <template slot-scope="scope">
                        <el-checkbox
                          v-model="selectedMultiToolsToReturnRows[scope.row.id]"
                          @change="handleSelection(scope.row, $event)"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                  <!-- button -->
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                      <base-button @click="unreserveMultiTool" type="standard" fill>{{$t('common.confirm')}}</base-button>
                  </div>
                </div>
              </div>
            </card>
          </modal>
          <modal :show.sync="showProjectPickModal" class="modal-scroll-fix white-content">
            <card>
              <h4 class="card-title">{{$t('tools.pickProject')}}</h4>

              <div class="col-md-12">
                  <div>
                    <div
                      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                    >
                      <base-input>
                        <el-input
                          class="mb-3 search-input"
                          clearable
                          prefix-icon="el-icon-search"
                          :placeholder="$t('common.searchRecords')"
                          v-model="searchQuery"
                          aria-controls="datatables"
                          @keyup.native="searchProjects"
                          @clear="handleClearSearchProjects"
                        >
                        </el-input>
                      </base-input>

                    </div>

                  <!-- Mobile Table -->
                  <el-table :data="projects" >
                    <el-table-column :label="$t('common.info')" min-width="150">
                      <template slot-scope="scope">
                        <div>
                          <div><strong>{{$t('projects.key')}}:</strong> {{ scope.row.key }}</div>
                          <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column  width="50">
                      <template slot-scope="scope" >
                        <el-radio  v-model="selectedProject" :label="scope.row.id"></el-radio>
                      </template>
                    </el-table-column>
                  </el-table>

                  </div>
                  <div
                    slot="footer"
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <div class="">
                      <p class="card-category">
                        {{ $t('pagination.showing') }} {{ projectsFrom + 1 }} {{ $t('pagination.to') }} {{ projectsTo }} {{ $t('pagination.of') }} {{ projectsTotal }} {{ $t('pagination.entries') }}
                      </p>
                    </div>
                    <base-pagination
                      @input="projectsUpdatePage"
                      class="pagination-no-border"
                      v-model="projectsPagination.currentPage"
                      :per-page="projectsPagination.perPage"
                      :total="projectsTotal"
                    >
                    </base-pagination>
                  </div>
                  <!-- button -->
                <div class="row">
                  <div class="col-sm d-flex justify-content-end">
                      <base-button @click="reserveTool" type="standard" fill>{{$t('common.confirm')}}</base-button>
                  </div>
                </div>
              </div>
            </card>
          </modal>

          <modal :show.sync="showUpdateReservationDaysModal" class="white-content">
            <h3 class="card-title">{{$t('common.changeDates')}}</h3>
            <div class="row">
              <div class="col-12">
                <div class="calendar d-flex align-items-center">
                  <div class="d-flex align-items-center" style="flex: 1;">
                    <el-date-picker
                      class="flex-grow-1 form-group"
                      type="date"
                      :placeholder="$t('common.from')"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-model="updatedReservationDate"
                    >
                    </el-date-picker>
                  </div>
                  <span class="mx-2">-</span>
                  <div class="d-flex align-items-center" style="flex: 1;">
                    <el-date-picker
                      class="flex-grow-1 form-group"
                      type="date"
                      :placeholder="$t('common.to')"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-model="updatedUnreservationDate"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <base-checkbox
                  v-model="setPriceZero"
                >
                  {{$t('common.totalAmount')}} 0kr
                </base-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="updateReservationDays" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <modal :show.sync="showReserveMultiToolModal" class="white-content">
            <h3 style="margin-bottom: 0px;" class="card-title">{{$t('tools.reserveTool')}}</h3>
            <h4 >{{$t('tools.available')}}: {{availableToolsInMultiTool}}</h4>
            <div class="row">
              <div :class="this.userRole !== 'User' ? 'col-6' : 'col-12'">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.quantity')}} *</label>
                <base-input
                :placeholder="$t('tools.quantity')"
                v-model="multiToolQuantity"
                ></base-input>
              </div>
              <div class="col-6" v-if="this.userRole !== 'User'">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.reservationType')}} *</label>
                <select v-model="multiToolReservationType" class="form-control" :placeholder="$t('tools.reservationType')">
                  <option
                    v-for="status in multiToolReservationTypeOptions"
                    :key="status"
                    :value="status"
                    :label="status"
                  >
                  </option>
                </select>
              </div>
            </div>
            <div class="row" v-if="this.userRole !== 'User'">
              <div class="col-6"  v-if="this.multiToolReservationType !== this.$t('tools.reserve')">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.loanRentTo')}} *</label>
                <base-input
                :placeholder="$t('tools.loanRentTo')"
                v-model="multiToolLoanRentExternalUser"
                ></base-input>
              </div>
              <div class="col-6"  v-if="this.multiToolReservationType === this.$t('tools.rent')">
                <label class="col-md-4 col-form-label toolgroup-modal-label">{{$t('tools.dailyPrice')}}</label>
                <base-input
                :placeholder="$t('tools.dailyPrice')"
                v-model="multiToolLoanRentDailyPrice"
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-sm d-flex justify-content-end">
                <base-button @click="startReserveMultiTool" type="standard" fill>{{$t('common.submit')}}</base-button>
              </div>
            </div>
          </modal>

          <template v-slot:table-select>
            <div>
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  v-for="(option, index) in filteredTablesCategories"
                  :key="option.name"
                  class="btn btn-sm btn-simple"
                  :class="[{ active: activeIndex === index}, selectClass]"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initTable(index)"
                    name="options"
                    autocomplete="off"
                    :checked="activeIndex === index"
                  />
                  <span class="d-none d-sm-block" :style="{ color: activeIndex === index ? 'white' : ''}">{{ option.name }}</span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon" :style="{ color: activeIndex === index ? 'white' : ''}"></i>
                  </span>
                </label>
              </div>
            </div>
          </template>
          <div v-if="tableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myToolsTable.data"
                :tableColumns="myToolsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myToolsFrom + 1 }} {{ $t('pagination.to') }} {{ myToolsTo }} {{ $t('pagination.of') }} {{ myToolsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myToolsUpdatePage"
                class="pagination-no-border"
                v-model="myToolsPagination.currentPage"
                :per-page="myToolsPagination.perPage"
                :total="myToolsTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="externalReservationDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="externalReservationsTable.data"
                :tableColumns="externalReservationsTable.columns"
                :allowClick="true"
                @row-click="goToTool"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ externalReservationFrom + 1 }} {{ $t('pagination.to') }} {{ externalReservationTo }} {{ $t('pagination.of') }} {{ externalReservationTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="externalReservationUpdatePage"
                class="pagination-no-border"
                v-model="externalReservationPagination.currentPage"
                :per-page="externalReservationPagination.perPage"
                :total="externalReservationTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="carsTableDataReady">
            <div class="table-responsive">
              <el-base-table
                :tableData="myCarsTable.data"
                :tableColumns="myCarsTable.columns"
                :allowClick="true"
                @row-click="goToCar"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myCarsFrom + 1 }} {{ $t('pagination.to') }} {{ myCarsTo }} {{ $t('pagination.of') }} {{ myCarsTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myCarsUpdatePage"
                class="pagination-no-border"
                v-model="myCarsPagination.currentPage"
                :per-page="myCarsPagination.perPage"
                :total="myCarsTotal"
              >
              </base-pagination>
            </div>
          </div>
          <div v-if="reservationHistoryDataReady">
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('common.searchRecords')"
                  v-model="historySearchQuery"
                  aria-controls="datatables"
                  @keyup.native="searchReservationHistory"
                  @clear="handleClearSearchReservationHistory"
                >
                </el-input>
              </base-input>

            </div>
            <div class="table-responsive">
              <el-base-table
                :tableData="myReservationHistoryTable.data"
                :tableColumns="myReservationHistoryTable.columns"
                :enableEdit="true"
                :enableDelete="false"
                :actionsInline="true"
                :allowClick="true"
                @row-click="goToTool"
                @edit="handleReservationEdit"
              />
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('pagination.showing') }} {{ myReservationHistoryFrom + 1 }} {{ $t('pagination.to') }} {{ myReservationHistoryTo }} {{ $t('pagination.of') }} {{ myReservationHistoryTotal }} {{ $t('pagination.entries') }}
                </p>
              </div>
              <base-pagination
                @input="myReservationHistoryUpdatePage"
                class="pagination-no-border"
                v-model="myReservationHistoryPagination.currentPage"
                :per-page="myReservationHistoryPagination.perPage"
                :total="myReservationHistoryTotal"
              >
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import ElBaseTable from "../../components/ElBaseTable.vue";
import QrScanner from '@/components/QRScanner/QrScanner.vue'
import { BasePagination,Modal } from 'src/components';
import api from "../../services/api"
import {Select, Option, Table, TableColumn, Radio, Checkbox, DatePicker } from 'element-ui';
import swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { getCustomerConfig } from '../Settings/customerConfigHelper';


export default {
    components: {
      'el-base-table': ElBaseTable,
      QrScanner,
      BasePagination,
      Modal,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      'el-checkbox': Checkbox,
      'el-radio': Radio,
      [DatePicker.name]: DatePicker,
    },
    computed: {
      myToolsTo() {
        let highBound = this.myToolsFrom + this.myToolsPagination.perPage;
        if (this.myToolsTotal < highBound) {
          highBound = this.myToolsTotal;
        }
        return highBound;
      },
      myToolsFrom() {
        return this.myToolsPagination.perPage * (this.myToolsPagination.currentPage - 1);
      },
      myToolsTotal() {
        return this.myToolsPagination.total;
      },
      myCarsTo() {
        let highBound = this.myCarsFrom + this.myCarsPagination.perPage;
        if (this.myCarsTotal < highBound) {
          highBound = this.myCarsTotal;
        }
        return highBound;
      },
      myCarsFrom() {
        return this.myCarsPagination.perPage * (this.myCarsPagination.currentPage - 1);
      },
      myCarsTotal() {
        return this.myCarsPagination.total;
      },
      externalReservationTo() {
        let highBound = this.externalReservationFrom + this.externalReservationPagination.perPage;
        if (this.externalReservationTotal < highBound) {
          highBound = this.externalReservationTotal;
        }
        return highBound;
      },
      externalReservationFrom() {
        return this.externalReservationPagination.perPage * (this.externalReservationPagination.currentPage - 1);
      },
      externalReservationTotal() {
        return this.externalReservationPagination.total;
      },
      projectsTo() {
        let highBound = this.projectsFrom + this.projectsPagination.perPage;
        if (this.projectsTotal < highBound) {
          highBound = this.projectsTotal;
        }
        return highBound;
      },
      projectsFrom() {
        return this.projectsPagination.perPage * (this.projectsPagination.currentPage - 1);
      },
      projectsTotal() {
        return this.projectsPagination.total;
      },
      myReservationHistoryTo() {
        let highBound = this.myReservationHistoryFrom + this.myReservationHistoryPagination.perPage;
        if (this.myReservationHistoryTotal < highBound) {
          highBound = this.myReservationHistoryTotal;
        }
        return highBound;
      },
      myReservationHistoryFrom() {
        return this.myReservationHistoryPagination.perPage * (this.myReservationHistoryPagination.currentPage - 1);
      },
      myReservationHistoryTotal() {
        return this.myReservationHistoryPagination.total;
      },
      myTablesCategories() {
        if(!this.disableCars)
        {
          return [{ name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings', showUser:true },
            { name: this.$t('projects.myReservationHistory'), icon: 'tim-icons icon-notes', showUser:true },
            { name: this.$t('cars.myCars'), icon: 'tim-icons icon-bus-front-12', showUser:true },
            { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run', showUser:false }
          ];
        }
        else
        {
          return [{ name: this.$t('tools.myTools'), icon: 'tim-icons icon-settings', showUser:true },
            { name: this.$t('projects.myReservationHistory'), icon: 'tim-icons icon-notes', showUser:true },
            { name: this.$t('projects.toolsLoanedRentedOnProject'), icon: 'tim-icons icon-user-run', showUser:false }
          ];
        }

      },
      filteredTablesCategories() {
        return this.myTablesCategories.filter(option => option.showUser || this.userRole !== 'User');
      },
      selectClass() {
        let darkMode = localStorage.getItem('darkMode');
        // If darkMode does not exist in localStorage, default it to 'false'
        if (darkMode === null) {
          darkMode = 'false';
        }
        if (darkMode === 'false') {
          return 'btn-standard';
        } else {
          return 'btn-primary';
        }
      }
    },
    data() {
      return {
        myToolsTable: {
          title: this.$t('tools.myTools'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: true, isImage: true },
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'project', label: this.$t('common.project'), minWidth: 100 },
            { prop: 'daysLeft', label: this.$t('tools.daysLeft'), minWidth: 60 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 60},
            { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 60},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'common.project':'project', 'tools.daysLeft': 'daysLeft', 'tools.reservation': 'reservationDate','tools.quantity': 'quantity'}},
          ],
          data: [],
        },
        myCarsTable: {
          title: this.$t('cars.myCars'),
          columns: [
            { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
            { prop: 'licencePlate', label: this.$t('cars.licencePlate'), minWidth: 200 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.name': 'name', 'cars.licencePlate':'licencePlate','tools.reservation': 'reservationDate'}},
          ],
          data: [],
        },
        externalReservationsTable: {
          title: this.$t('projects.toolsLoanedRentedOnProject'),
          columns: [
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'name', label: this.$t('common.tool'), minWidth: 200 },
            { prop: 'status', label: this.$t('tools.loanedRented'), minWidth: 80 },
            { prop: 'externalUser', label: this.$t('tools.loanedRentedTo'), minWidth: 90 },
            { prop: 'reservationDate', label: this.$t('tools.reservation'), minWidth: 80},
            { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 60},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'name', 'common.status':'status', 'tools.loanedRentedTo': 'externalUser', 'tools.reservation': 'reservationDate','tools.quantity': 'quantity'}},
          ],
          data: [],
        },
        myReservationHistoryTable: {
          title: this.$t('projects.reservationHistory'),
          columns: [
            { prop: 'image', label: this.$t('common.image'), width: 80, mobileWidth: 100, showOnMobile: false, isImage: true },
            { prop: 'customerToolId', label: this.$t('common.customerToolId'), minWidth: 50 },
            { prop: 'tool', label: this.$t('common.tool'), minWidth: 150 },
            { prop: 'project', label: this.$t('projects.projects'), minWidth: 150},
            { prop: 'reserved', label: this.$t('tools.reserved'), minWidth: 100 },
            { prop: 'returned', label: this.$t('tools.returned'), minWidth: 100 },
            { prop: 'totalPrice', label: this.$t('common.totalAmount'), minWidth: 100 },
            { prop: 'quantity', label: this.$t('tools.quantity'), minWidth: 60},
            { label: this.$t('common.info'), minWidth: 150, showOnMobile: true, isCompound: true, compound: { 'common.customerToolId': 'customerToolId', 'common.tool': 'tool', 'projects.projects': 'project', 'tools.reserved': 'reserved', 'tools.returned': 'returned', 'common.totalAmount': 'totalPrice','tools.quantity': 'quantity'}},
          ],
          data: [],
        },
        projects: [],
        projectTableColumns: [
          { prop: 'key', label: this.$t('projects.key'), minWidth: 150 },
          { prop: 'name', label: this.$t('common.name'), minWidth: 200 },
        ],
        myToolsInitialPage: true,
        myCarsInitialPage: true,
        projectsInitialPage: true,
        multiToolReturningName: '',
        externalReservationInitialPage: true,
        myToolsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myReservationHistoryPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        myCarsPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        externalReservationPagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        projectsPagination: {
          perPage: 5,
          currentPage: 1,
          total: 0
        },
        userId: '',
        tableDataReady: false,
        allowAllUsersToReturn: false,
        carsTableDataReady: false,
        externalReservationDataReady: false,
        reservationHistoryDataReady: false,
        showProjectPickModal:false,
        showUpdateReservationDaysModal: false,
        showReserveMultiToolModal: false,
        showReturnMultiToolModal: false,
        activeIndex: 0,
        cardTitle: this.$t('tools.myTools'),
        searchQuery: '',
        historySearchQuery: '',
        selectedProject: null,
        isMobile: window.innerWidth <= 768,
        currentQRContent: null,
        updatedReservationDate: null,
        updatedUnreservationDate: null,
        latestUnreservedId: '',
        userRole: '',
        currentLatitude: '',
        currentLongitude: '',
        availableToolsInMultiTool: 'N/A',
        multiToolQuantity: '',
        multiToolLoanRentExternalUser: '',
        multiToolLoanRentDailyPrice: '',
        multiToolReservationType: this.$t('tools.reserve'),
        reservingMultiToolInProgress: false,
        multiToolIdsToReturn:[],
        multiToolIdsToExternalReturn:[],
        multiToolReturnTable: [],
        selectedMultiToolsToReturnRows: {},
        multiToolReservationTypeOptions:[
          this.$t('tools.reserve'),
          this.$t('tools.loan'),
          this.$t('tools.rent'),
        ],
        refreshHistory: false,
        disableCars: false,
        setPriceZero: false
      };
    },
    created() {
      window.addEventListener('resize', this.updateIsMobile);
      const token = Cookies.get('accessToken');
      const decodedToken = jwtDecode(token);
      this.userRole = decodedToken.role
      this.userId = decodedToken.UserIdentifier
      this.getCurrentLocation();
      getCustomerConfig('DefaultPageSize')
      .then(config => {
        if(config === '')
        {
          this.myToolsPagination.perPage = 10
          this.myReservationHistoryPagination.perPage = 10
          this.myCarsPagination.perPage = 10
          this.externalReservationPagination.perPage = 10
        }
        else
        {
          this.myToolsPagination.perPage = config
          this.myReservationHistoryPagination.perPage = config
          this.myCarsPagination.perPage = config
          this.externalReservationPagination.perPage = config
        }
        this.fetchTableData();
      })
      getCustomerConfig('DisableCars')
      .then(config => {
        if(config === '')
        {
          this.disableCars = false

        }
        else
        {
          this.disableCars = config
        }
      })
      getCustomerConfig('AllowAllUsersToReturn')
        .then(config => {
          if(config === '')
          {
            this.allowAllUsersToReturn = false
          }
          else
          {
            this.allowAllUsersToReturn = config
          }
        })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateIsMobile);
    },
    methods: {
      searchProjects(event){
        this.projectsPagination.currentPage = 1;
        const queryLength = this.searchQuery.length;
        if (event.key === 'Backspace' || event.key === 'Enter') {
          this.fetchProjectData();
        }
        else {
          if (queryLength > 1) {
            if (queryLength % 2 === 0) {
              this.fetchProjectData();
            }
          }
        }
      },
      fetchProjectData() {
        let getProjectsUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        api.get(getProjectsUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      handleClearSearchProjects(){
        this.fetchProjectTableData();
      },
      handleClearSearchReservationHistory() {
        this.myReservationHistoryPagination.currentPage = 1;
        this.fetchHistoryTableData();
      },
      initTable(index) {
        // Note reserve and unreserve ToolGroups has the active index hardcoded
        // Needs to be updated at the same time so it does not break
        this.activeIndex = index;
        if (this.activeIndex === 0) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('tools.myTools');
          if (this.myToolsTable.data.length <= 0) {
            this.fetchTableData();
          }
          else {
            this.tableDataReady = true;
          }
        }
        else if (this.activeIndex === 1) {
          this.externalReservationDataReady = false;
          this.carsTableDataReady = false;
          this.tableDataReady = false;
          this.cardTitle = this.$t('projects.myReservationHistory');
          if (this.myReservationHistoryTable.data.length <= 0) {
            this.fetchHistoryTableData();
          }
          else {
            this.reservationHistoryDataReady = true;
          }
        }
        else if (this.activeIndex === 2 && !this.disableCars) {
          this.tableDataReady = false;
          this.externalReservationDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('cars.myCars')
          if (this.myCarsTable.data.length <= 0) {
            this.fetchCarsData();
          }
          else {
            this.carsTableDataReady = true;
          }
        }
        else if(this.activeIndex === 3 || (this.activeIndex === 2 && this.disableCars)) {
          this.tableDataReady = false;
          this.carsTableDataReady = false;
          this.reservationHistoryDataReady = false;
          this.cardTitle = this.$t('projects.toolsLoanedRentedOnProject')
          if (this.externalReservationsTable.data.length <= 0) {
            this.fetchExternalReservationData();
          }
          else {
            this.externalReservationDataReady = true;
          }
        }
      },
      handleReservationEdit(index, row) {
        swal.fire({
          title: this.$t('tools.updateReservedDays'),
          text: `${this.$t('tools.youHadToolFrom')} ${row.reserved} - ${row.returned} \n ${this.$t('common.totalAmount')} ${row.totalPrice} Kr.`,
          icon: 'success',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
            cancelButton: 'btn btn-success btn-fill'
          },
          confirmButtonText: this.$t('tools.updateReservedDays'),
          cancelButtonText: this.$t('delete.cancel'),
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.latestUnreservedId = row.reservationId;
            this.updatedReservationDate = this.formatDate(row.reserved);
            this.updatedUnreservationDate = this.formatDate(row.returned);
            this.showUpdateReservationDaysModal = true;
            this.refreshHistory = true;
          }
        });
      },
      formatDate(date) {
        let day, month, year;
        if (date.includes("/")) {
          [day, month, year] = date.split("/");
        } 
        else {
          [day, month, year] = date.split(".");
          day = day.padStart(2, '0');
          month = month.padStart(2, '0');
        }
        return `${year}-${month}-${day}`;
      },
      searchReservationHistory(event){
        this.myReservationHistoryPagination.currentPage = 1;
        const queryLength = this.historySearchQuery.length;
        if (event.key === 'Backspace' || event.key === 'Enter'|| Number.isInteger(Number(this.historySearchQuery))) {
          this.fetchHistoryTableData();
        }
        else {
          if (queryLength > 1) {
            if (queryLength % 2 === 0) {
              this.fetchHistoryTableData();
            }
          }
        }
      },
      async fetchHistoryTableData() {
        let toolReserveUrl = `/Tool/Reservations?currentPage=${this.myReservationHistoryPagination.currentPage}&pageSize=${this.myReservationHistoryPagination.perPage}&forCurrentUser=true&unreserved=true&OrderByDescending=true&OrderByReservedTimestamp=false`;
        if (this.historySearchQuery !== '') {
          toolReserveUrl += `&SearchString=${this.historySearchQuery}`;
        }
        try {
          const response = await api.get(toolReserveUrl);
          this.myReservationHistoryPagination.total = response.data.totalItems;
          this.myReservationHistoryTable.data = response.data.result.map(item => {
            return {
              toolId: item.toolId,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
              tool: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              project: item.project.name,
              totalDays: this.calculateDays(item.reservedTimestamp, item.unreservedTimestamp),
              'reserved': new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              'returned': new Date(item.unreservedTimestamp).toLocaleDateString('is-IS'),
              totalPrice: item.totalPrice,
              reservationId: item.id,
              quantity: item.quantity
            };
          });
          this.reservationHistoryDataReady = true;
        }
        catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
      },
      async fetchCarsData() {
        let toolGroupReserveUrl = `/ToolGroup/Reservations/?forCurrentUser=true&currentPage=${this.myCarsPagination.currentPage}&pageSize=${this.myCarsPagination.perPage}&toolGroupTypeId=1`
        api.get(toolGroupReserveUrl)
        .then(response => {
          this.myCarsPagination.total = response.data.totalItems;
          this.myCarsTable.data = response.data.result.map(item => {
            let licencePlate = ''
            if(item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
            {
              licencePlate = item.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
            }
            return {
              toolGroupId: item.toolGroupId,
              name: item.toolGroup.name,
              licencePlate: licencePlate,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
            };
          });
          this.carsTableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      async fetchTableData() {
        let toolReserveUrl = `/Tool/Reservations?forCurrentUser=true&currentPage=${this.myToolsPagination.currentPage}&pageSize=${this.myToolsPagination.perPage}`
        api.get(toolReserveUrl)
        .then(response => {
          this.myToolsPagination.total = response.data.totalItems;
          this.myToolsTable.data = response.data.result.map(item => {
            let daysLeft = ''
            if(item.tool.maxReservationDays && item.tool.maxReservationDays !== 0){
              const currentDate = new Date();
              const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
              const daysSinceReservation = Math.round((currentDate - new Date(item.reservedTimestamp)) / oneDayInMilliseconds);
              daysLeft = item.tool.maxReservationDays  - daysSinceReservation;
            }
            else{
              daysLeft = '∞'
            }

            return {
              reservationId: item.id,
              toolId: item.tool.id,
              image: 'data:image/jpeg;base64,' + item.tool.toolImageThumbnailBytes,
              tool: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              project: item.project.name,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              daysLeft: daysLeft,
              quantity: item.quantity
            };
          });
          this.tableDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });

      },
      async fetchProjectTableData(){
        let projectUrl = ''
        if(this.searchQuery === '')
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&projectStatusId=2`
        }
        else
        {
          projectUrl = `/Project?currentPage=${this.projectsPagination.currentPage}&pageSize=${this.projectsPagination.perPage}&searchString=${this.searchQuery}&projectStatusId=2`
        }
        api.get(projectUrl)
        .then(response => {
            this.projectsPagination.total = response.data.totalItems;
            this.projects = response.data.result.map(item => {
            return {
                id: item.id,
                key: item.key,
                name: item.name,
            };
            });
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToRetrieveData'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },
      async fetchExternalReservationData() {
        let exernalReservationUrl = `/Tool/Reservations/External?forCurrentUser=true&currentPage=${this.externalReservationPagination.currentPage}&pageSize=${this.externalReservationPagination.perPage}`
        api.get(exernalReservationUrl)
        .then(response => {
          this.externalReservationPagination.total = response.data.totalItems;
          this.externalReservationsTable.data = response.data.result.map(item => {
            let loanRent = item.rented ? this.$t('tools.rented') : loanRent = this.$t('tools.loaned');

            return {
              toolId: item.tool.id,
              name: item.tool.name,
              customerToolId: '#' + item.tool.customerToolId,
              externalUser: item.externalUser,
              status: loanRent,
              reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
              quantity: item.quantity
            };
          });
          this.externalReservationDataReady = true;
        })
        .catch(error => {
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
            console.error(error);
        });
      },
      myToolsUpdatePage (item) {
        this.myToolsPagination.currentPage = item;
        this.fetchTableData();
      },
      projectsUpdatePage (item) {
        this.projectsPagination.currentPage = item;
        this.fetchProjectTableData();
      },
      myCarsUpdatePage (item) {
        this.myCarsPagination.currentPage = item;
        this.fetchCarsData();
      },
      myReservationHistoryUpdatePage (item) {
        this.myReservationHistoryPagination.currentPage = item;
        this.fetchHistoryTableData();
      },
      externalReservationUpdatePage (item) {
        this.externalReservationPagination.currentPage = item;
        if (this.externalReservationInitialPage) {
          this.externalReservationInitialPage = false;
        }
        else {
          this.fetchExternalReservationData();
        }
      },
      goToTool(item) {
          this.$router.push({ name: 'Tool', params: { toolId: item.toolId } });
      },
      goToCar(item) {
        this.$router.push({ name: 'Car', params: { toolGroupId: item.toolGroupId.toString() } });
      },
      async reserveTool(){
        if(this.selectedProject === null){
          this.$notify({
                message: this.$t('tools.pickProject'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
              return;
        }
        try {
          if(this.reservingMultiToolInProgress === true)
          {
            this.showReserveMultiToolModal = true;
          }
          else
          {
            const response = await api.post('/Tool/Reserve', {
              toolId: this.currentQRContent,
              projectId: this.selectedProject
            });
              this.$notify({
                message: this.$t('tools.toolReserved'),
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
            });
            this.fetchTableData();
            this.initTable(0)

            this.showProjectPickModal = false;
            this.selectedProject = null;
            this.searchQuery = ''

            this.projectsPagination= {
              perPage: 5,
              currentPage: 1,
              total: 0
            }

          }
        }
        catch (error) {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('tools.unableToReserveTool'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        }


      },

      async reserveCar(carId) {
        const response = await api.post('/ToolGroup/Reserve', {
            toolGroupId: carId,
            userId: this.userId
          }).then(response => {
              this.$notify({
                  message: this.$t('cars.carReserved'),
                  icon: "tim-icons icon-check-2",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "success",
                  timeout: 1500,
              });
              this.fetchCarsData()
              this.initTable(2)

          })
          .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                  message: this.$t('common.unableToUpdate'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
              });
          });
        },

      async startReserve(qrContent){
        if(qrContent.includes("car"))
        {
           qrContent = qrContent.split("/").pop();
          this.currentQRContent = qrContent
           let statusUrl = `/Toolgroup/Info/${this.currentQRContent}`
          api.get(statusUrl)
          .then(response => {
            if(response.data.allActiveToolGroupReservations && response.data.allActiveToolGroupReservations.length > 0)
            {
              if(response.data.allActiveToolGroupReservations[0].user.id === this.userId)
              {
                this.$notify({
                  message: this.$t('cars.alreadyAssignedToYou'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "warning",
                    timeout: 0,
                });
                return;
              }

              let reservingUser = response.data.allActiveToolGroupReservations[0].user.firstName + ' ' + response.data.allActiveToolGroupReservations[0].user.lastName
              swal.fire({
                title: this.$t('cars.reservedByOtherUser') + ' ' + reservingUser,
                text: this.$t('cars.doYouWantToOvertake'),
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('cars.yesOvertake'),
                cancelButtonText: this.$t('delete.cancel'),
                buttonsStyling: false
              }).then(result => {
                if (result.value) {
                  api.put(`/ToolGroup/Unreserve/${this.currentQRContent}`).then(response => {
                      this.reserveCar(this.currentQRContent);
                  })
                  .catch(error => {
                      console.error('API request error:', error);
                      this.$notify({
                        message: this.$t('common.unableToUpdate'),
                          icon: "tim-icons icon-alert-circle-exc",
                          horizontalAlign: "center",
                          verticalAlign: "top",
                          type: "danger",
                          timeout: 0,
                      });
                    });
                }
              });
            }
            else
            {
              this.reserveCar(this.currentQRContent);
            }
          });
        }
        else
        {
          //validate QRContent
          qrContent = qrContent.split("/").pop();
          if(this.validateUuid(qrContent)){
            this.currentQRContent = qrContent
          }
          else{
            this.$notify({
              message: this.$t('tools.invalidToolId') + " " + this.currentQRContent,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 2000,
            });
            return;
          }

          //Check info of tool
          let statusUrl = `/Tool/Info/${this.currentQRContent}`
          api.get(statusUrl)
          .then(response => {
              let needUserConfirmation = false
              let confirmationInfo = ''
              let confirmationQuestion = this.$t('tools.doYouWantToOvertake')
              let unreserve = false
              let externalUnreserve = false
              let takeOverCar = false
              if(response.data.isReserved === true){
                needUserConfirmation = true
                unreserve = true
                confirmationInfo = this.$t('tools.reservedByOtherUser') + ' ' + response.data.reservedBy
              }
              else if(response.data.isExternallyReserved === true){
                externalUnreserve = true
                needUserConfirmation = true
                confirmationInfo = this.$t('tools.loanedRentedByOtherUser') + ' ' + response.data.externallyReservedBy
              }
              else if(response.data.isInToolGroup === true){
                takeOverCar = true
                needUserConfirmation = true
                confirmationInfo = this.$t('tools.toolIsInCar') + ' ' + response.data.toolGroupName
                if(response.data.isToolGroupReserved){
                  confirmationInfo = confirmationInfo + ' ' + this.$t('tools.carReservedBy') + ' ' + response.data.toolGroupReservedBy
                }
              }
              else if(response.data.multiTool === true)
              {
                if(response.data.totalCount === response.data.inUse)
                {
                  this.$notify({
                    message: `${this.$t('errors.nothingAvailable')}`,
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                  });
                }
                else
                {
                  this.reservingMultiToolInProgress = true;
                  this.availableToolsInMultiTool = response.data.totalCount - response.data.inUse
                  this.fetchProjectTableData();
                  this.showProjectPickModal = true
                }
                return;

              }

              if(needUserConfirmation === true){
                swal.fire({
                title: confirmationInfo,
                text: confirmationQuestion,
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success btn-fill',
                  cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: this.$t('tools.yesOvertake'),
                cancelButtonText: this.$t('delete.cancel'),
                buttonsStyling: false
              }).then(result => {
                if (result.value) {
                  let takeOverUrl = ''
                  if(unreserve === true){
                    takeOverUrl = `/Tool/Unreserve/${this.currentQRContent}`
                  }
                  else if(externalUnreserve === true){
                    takeOverUrl = `/Tool/Unreserve/External/${this.currentQRContent}`
                  }
                  else if(takeOverCar=== true){
                    this.fetchProjectTableData();
                    this.showProjectPickModal = true
                    return;
                  }

                  api.put(takeOverUrl).then(response => {
                    this.fetchProjectTableData();
                    this.showProjectPickModal = true
                  })
                  .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  }
                )
                .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  }

                );
                }
                else{
                  return;
                }
              });
              }
              else{
                this.fetchProjectTableData();
                this.showProjectPickModal = true
              }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
      },
      async unreserve(qrContent) {
        try {
          if(qrContent.includes("car"))
          {
            qrContent = qrContent.split("/").pop();
            this.currentQRContent = qrContent
            let statusUrl = `/Toolgroup/Info/${this.currentQRContent}`
            api.get(statusUrl)
            .then(response => {
              if(response.data.allActiveToolGroupReservations && response.data.allActiveToolGroupReservations.length > 0)
              {
                if(response.data.allActiveToolGroupReservations[0].user.id !== this.userId)
                {
                  this.$notify({
                    message: this.$t('cars.notAssignedToYou'),
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                  });
                  return;
                }
                 api.put(`/ToolGroup/Unreserve/${this.currentQRContent}`).then(response => {
                    this.$notify({
                      message: this.$t('cars.youReturned'),
                        icon: "tim-icons icon-alert-circle-exc",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success",
                        timeout: 1500,
                    });
                  this.fetchCarsData()
                  this.initTable(2)

                })
                .catch(error => {
                    console.error('API request error:', error);
                    this.$notify({
                      message: this.$t('common.unableToUpdate'),
                        icon: "tim-icons icon-alert-circle-exc",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                        timeout: 0,
                    });
                  });
              }
              else
              {
                this.$notify({
                  message: this.$t('cars.notAssignedToYou'),
                    icon: "tim-icons icon-alert-circle-exc",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                    timeout: 0,
                });
              }
            });
          }
          else
          {
            qrContent = qrContent.split("/").pop();
            if(this.validateUuid(qrContent))
            {
              //Check if current user has tool reserved
              let statusUrl = `/Tool/Info/${qrContent}`
              api.get(statusUrl)
              .then(response => {
                let errorMessage = ''
                const token = Cookies.get('accessToken');
                const decodedToken = jwtDecode(token);

                if(response.data.locationInfo.requirePresenceForToolReturn === true)
                {
                  const distance = this.calculateDistance(this.currentLatitude, this.currentLongitude, response.data.locationInfo.latitude, response.data.locationInfo.longitude);
                  if (distance > response.data.locationInfo.radiusMeters) {
                    this.$notify({
                      message: this.$t('tools.toolNotAtLocation') + ': ' + response.data.locationInfo.address,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                    return;
                  }
                }

                if(response.data.multiTool)
                {
                  if((response.data.activeToolReservationsForCurrentUser == null || response.data.activeToolReservationsForCurrentUser.length === 0) &&
                    (response.data.activeExternalToolReservationsForCurrentUser == null || response.data.activeExternalToolReservationsForCurrentUser.length === 0)
                  )
                  {
                    this.$notify({
                      message: this.$t('tools.noToolsReserved'),
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "warning",
                      timeout: 1500,
                    });
                    return;
                  }
                  let reservations = response.data.activeToolReservationsForCurrentUser
                  .map(item => {
                    return {
                      id: item.id,
                      quantity: item.quantity,
                      reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
                      project: item.project.name,
                      type: this.$t('tools.reserved')
                    };
                  });

                  let externalReservations = response.data.activeExternalToolReservationsForCurrentUser
                  .map(item => {
                    return {
                      id: item.id,
                      quantity: item.quantity,
                      reservationDate: new Date(item.reservedTimestamp).toLocaleDateString('is-IS'),
                      project: item.project.name,
                      type: item.rented ? this.$t('tools.rented'): this.$t('tools.loaned')
                    };
                  });

                  this.multiToolReturnTable = reservations.concat(externalReservations)

                  this.multiToolReturningName = response.data.name
                  this.showReturnMultiToolModal = true;
                }
                else
                {
                  let differentUserConfirmationNeeded = false;
                  if(response.data.isReserved !== true){
                    errorMessage = this.$t('tools.toolNotReserved')
                  }
                  else if(response.data.userId !== this.userId){
                    if(this.allowAllUsersToReturn)
                    {
                      differentUserConfirmationNeeded = true
                    }
                    else
                    {
                      errorMessage = this.$t('tools.reservedByDifferentUser') + " " + response.data.reservedBy
                    }
                  }
                  if(errorMessage !== ''){
                    this.$notify({
                      message: errorMessage,
                      icon: "tim-icons icon-alert-circle-exc",
                      horizontalAlign: "center",
                      verticalAlign: "top",
                      type: "danger",
                      timeout: 0,
                    });
                  }
                  else{
                    if(differentUserConfirmationNeeded)
                    {
                      swal.fire({
                        title: `${response.data.reservedBy} ${this.$t('tools.hasThisTool')}`,
                        text: this.$t('tools.doYouWantToReturn'),
                        icon: 'warning',
                        showCancelButton: true,
                        customClass: {
                          confirmButton: 'btn btn-success btn-fill',
                          cancelButton: 'btn btn-danger btn-fill'
                        },
                        confirmButtonText: this.$t('tools.return'),
                        cancelButtonText: this.$t('delete.cancel'),
                        buttonsStyling: false
                      }).then(result => {
                      if (result.value) {
                        this.unreserveTool(qrContent)
                      }
                      else{
                        return;
                      }
                      });
                    }
                    else
                    {
                      this.unreserveTool(qrContent)
                    }
                  }
                }

              })
              .catch(error => {
                console.error('API request error:', error);
                this.$notify({
                  message: this.$t('common.unableToRetrieveData'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
                });
              });
            }
            else {
              this.$notify({
                message: this.$t('tools.invalidToolId') + ": " + toolId,
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 2000,
              });
            }
          }

        }
        catch (error) {
          this.$notify({
            message: this.$t('tools.toolReturnError'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          console.error(error);
        }
      },
      formatDateResponse(dateString) {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 10);
      },
      unreserveTool(qrContent)
      {
        let unreserveUrl = `/Tool/Unreserve/${qrContent}`;
        api.put(unreserveUrl).then(response => {
          this.fetchTableData();
          this.initTable(0)
          //Check if user wants to override totalprice
          if(response.data.totalPrice && response.data.totalPrice>0){
            this.latestUnreservedId = response.data.id
            let totalDays = this.calculateDays(response.data.reservedTimestamp, response.data.unreservedTimestamp);
            swal.fire({
            title: this.$t('tools.successfulUnreserve'),
            text: `${this.$t('tools.youHadToolFor')} ${totalDays} ${this.$t('tools.daysPrice')} ${response.data.totalPrice} Kr.`,
            icon: 'success',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            confirmButtonText: this.$t('tools.acceptTotalPrice'),
            cancelButtonText: this.$t('tools.updateReservedDays'),
            buttonsStyling: false
            }).then(result => {
            if (!result.value) {
              this.updatedReservationDate = this.formatDateResponse(response.data.reservedTimestamp);
              this.updatedUnreservationDate = this.formatDateResponse(response.data.unreservedTimestamp);
              this.showUpdateReservationDaysModal = true;
            }
            else{
              this.$notify({
                message: this.$t('tools.successfulUnreserve'),
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });
            }
            });
          }
          else{
            this.$notify({
              message: this.$t('tools.successfulUnreserve'),
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 1500,
            });
          }
        })
        .catch(error => {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.toolReturnError'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        });
      },

      async unreserveMultiTool()
      {
        if(this.multiToolIdsToReturn.length === 0 && this.multiToolIdsToExternalReturn.length === 0)
        {
          this.$notify({
            message: this.$t('errors.nothingSelected'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          return
        }

        if(this.multiToolIdsToReturn.length > 0)
        {
          let ids = this.multiToolIdsToReturn.join(',');
          await api.put(`/Tool/UnreserveByIds/`,{ids: ids});
          this.initTable(0)
          this.fetchTableData();
        }
        if(this.multiToolIdsToExternalReturn.length > 0)
        {
          let ids = this.multiToolIdsToExternalReturn.join(',');
          await api.put(`/Tool/UnreserveByIds/External`,{ids: ids});
          if(this.disableCars)
          {
            this.initTable(2)
          }
          else
          {
            this.initTable(3)
          }
          this.fetchExternalReservationData();
        }

        this.showReturnMultiToolModal = false;

        this.$notify({
          message: this.$t('tools.toolReturned'),
          icon: "tim-icons icon-check-2",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success",
          timeout: 1500,
        });



      },
      validateUuid(uuid) {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(uuid);
      },
      isValidDate(dateString) {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date);
      },
      updateReservationDataIsValid() {
        if (this.updatedReservationDate === null || this.updatedReservationDate === '' || this.updatedUnreservationDate === null || this.updatedUnreservationDate === '') {
          this.$notify({
            message: this.$t('errors.datesEmptyOrIncorrect'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
          return false;
        }

        if (!this.isValidDate(this.updatedReservationDate) || !this.isValidDate(this.updatedUnreservationDate)) {
          this.$notify({
            message: this.$t('errors.datesEmptyOrIncorrect'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
          return false;
        }

        if (new Date(this.updatedReservationDate) > new Date(this.updatedUnreservationDate)) {
          this.$notify({
            message: this.$t('errors.startDateBiggerThanEndDate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
          return false;
        }
        return true;
      },
      updateReservationDays(){
        if (!this.updateReservationDataIsValid()) {
				  return;
			  }
        var requestBody = this.setPriceZero ? 
          {updatedReservedTimestamp: this.updatedReservationDate, updatedUnreservedTimestamp: this.updatedUnreservationDate, totalPrice: 0} :
          {updatedReservedTimestamp: this.updatedReservationDate, updatedUnreservedTimestamp: this.updatedUnreservationDate};
          console.log(requestBody);
        api.put(`/Tool/Reservation/${this.latestUnreservedId}`,requestBody).then(response => {
          this.showUpdateReservationDaysModal = false;
          this.setPriceZero = false;
          this.updatedReservationDate = null;
          this.updatedUnreservationDate = null;
          swal.fire({
            title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill'
            },
            buttonsStyling: false
          });
          if (this.refreshHistory) {
            this.fetchHistoryTableData();
            this.refreshHistory = false;
          }
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });

      },
      async startReserveMultiTool()
      {
        if(!this.validateMultiToolReserveInput())
        {
          return;
        }

        try
        {
          //Check if we're loaning/renting or reserving:
          if(this.multiToolReservationType === this.$t('tools.reserve'))
          {
            const response = await api.post('/Tool/Reserve', {
              toolId: this.currentQRContent,
              projectId: this.selectedProject,
              quantity: this.multiToolQuantity
            }).then(response =>
            {
              this.$notify({
                message: this.$t('tools.toolReserved'),
                icon: "tim-icons icon-check-2",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });
            });

            this.fetchTableData();
            this.initTable(0)
          }
          else
          {
            let rented = false

            if(this.multiToolReservationType === this.$t('tools.rent'))
            {
              rented = true;
            }
            const response = await api.post('/Tool/Reserve/External', {
              toolId: this.currentQRContent,
              externalUser: this.multiToolLoanRentExternalUser,
              rented: rented,
              projectId: this.selectedProject,
              quantity: this.multiToolQuantity,
              dailyPrice: this.multiToolLoanRentDailyPrice !== '' ? Number(this.multiToolLoanRentDailyPrice) : null
            }).then(response =>
            {
              this.$notify({
                message: this.$t('tools.toolLoanedRented'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 1500,
              });
            })
            this.fetchExternalReservationData();
            if(this.disableCars)
            {
              this.initTable(2)
            }
            else
            {
              this.initTable(3)
            }
          }

          this.showReserveMultiToolModal = false;
          this.reservingMultiToolInProgress = false;
          this.multiToolQuantity = '';
          this.showProjectPickModal = false;
          this.selectedProject = null;
          this.searchQuery = ''

          this.projectsPagination= {
            perPage: 5,
            currentPage: 1,
            total: 0
          }

        }
        catch (error)
        {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('tools.unableToReserveTool'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 2000,
          });
        }


      },

      validateMultiToolReserveInput()
      {
        let errorMessage = ""
        let valid = true
        if(this.multiToolReservationType !== this.$t('tools.reserve') && this.multiToolLoanRentExternalUser === '')
        {
          errorMessage = this.$t('errors.externalUserEmpty')
          valid = false;
        }
        else if(this.multiToolQuantity === '' || isNaN(this.multiToolQuantity))
        {
          errorMessage = this.$t('errors.totalCountEmpty')
          valid = false;
        }
        else if(Number(this.multiToolQuantity) > Number(this.availableToolsInMultiTool))
        {
            errorMessage = this.$t('errors.quantityNotAvailable');
            valid = false;
        }

        else if(this.multiToolReservationType === this.$t('tools.rent'))
        {
          if(this.multiToolLoanRentDailyPrice !== '' && isNaN(this.multiToolLoanRentDailyPrice))
          {
            errorMessage = this.$t('errors.dailyPriceNotNumber');
            valid = false;
          }
        }

        if(errorMessage !== '')
        {
          this.$notify({
            message: errorMessage,
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
        }
        return valid;

      },
      handleSelection(row, checked) {
        if (checked)
        {
          if(row.type === this.$t('tools.reserved'))
          {
            if (!this.multiToolIdsToReturn.includes(row.id))
            {
              this.multiToolIdsToReturn.push(row.id);
            }
          }
          else
          {
            if (!this.multiToolIdsToExternalReturn.includes(row.id))
            {
              this.multiToolIdsToExternalReturn.push(row.id);
            }
          }

        }
        else
        {
          // Remove ID from the array if it's unchecked
          if(row.type === this.$t('tools.reserved'))
          {
            this.multiToolIdsToReturn = this.multiToolIdsToReturn.filter(item => item !== row.id);
          }
          else
          {
            this.multiToolIdsToExternalReturn = this.multiToolIdsToExternalReturn.filter(item => item !== row.id);
          }
        }
      },
      updateIsMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
      getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.currentLatitude = position.coords.latitude;
            this.currentLongitude = position.coords.longitude;
          }, error => {
            console.error(error);
          });
        } else {
            this.$notify({
              message: "Geolocation is not supported by this browser.",
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "warning",
              timeout: 0,
            });
        }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // Radius of the Earth in meters
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lon2 - lon1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // in meters
        return distance;
      },
    calculateDays(date1, date2) {
      var date1_ms = new Date(date1).getTime();
      var date2_ms = new Date(date2).getTime();

      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);

      // Convert back to days and return
      return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
    },
    getReservationLoanRentTranslation(reservationLoanRent)
    {
       switch (reservationLoanRent) {
            case "Loaned":
              return this.$t('tools.loaned')
            case "Rented":
              return this.$t('tools.rented')
            case "Reserved":
              return this.$t('tools.reserved')
            default:
              return 'N/A'
          }
    },
  },
};
</script>
<style scoped>
.unreserve-tools {
    display: flex;
    align-items: center;
    gap: 10px;
}
.el-radio__label{
  display: none !important;
}
.toolgroup-modal-label {
  padding-left: 0px;
}
.modal-scroll-fix{
  overflow-y: auto;
}
</style>
