<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card :title="computedTitle" :onCancel="cancel">
    <div class="row">
      <div class="col-md-4">
        <label class="col-form-label">{{$t('toolTypes.toolType')}} *</label>
        <base-input
          ref="toolTypeInput"
          v-model="toolType"
          :placeholder="$t('toolTypes.toolType')"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm d-flex justify-content-end">
        <base-button @click="saveToolType" type="standard" fill>{{$t('common.submit')}}</base-button>
      </div>
    </div>
  </card>
</template>
<script>
import api from "../../services/api"

export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    toolTypeProp: {
      type: String,
      required: false
    },
    toolTypeId: {
      type: Number,
      required: false
    }
  },
  computed: {
    computedTitle() {
      return this.editMode ? this.$t('toolTypes.editToolType') : this.$t('toolTypes.createToolType');
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      toolType: this.toolTypeProp,
    };
  },
  methods: {
    cancel()
    {
      this.$emit('close');
    },
    async saveToolType() {
      if (this.validateInput()) {
        if (this.editMode) {
          const response = await api.put('/ToolType/' + this.toolTypeId, { type: this.toolType }).then(request => {
            this.$notify({
              message: this.$t('toolTypes.toolTypeUpdated'),
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 1500,
            });

            this.$emit('refreshData');
            this.$emit('close');
          }).catch(error => {
            console.error(error)
            let errorMsg = error.response.data[0].errorMessage ? error.response.data[0].errorMessage : this.$t('common.somethingWentWrong');
            this.$notify({
              message: errorMsg,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
        else {
          const response = await api.post('/ToolType', { type: this.toolType }).then(request => {
            this.$notify({
              message: this.$t('toolTypes.toolTypeCreated'),
              icon: "tim-icons icon-check-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
              timeout: 1500,
            });

            this.$emit('refreshData');
            this.$emit('close');
          }).catch(error => {
            console.error(error)
            let errorMsg = error.response.data[0].errorMessage ? error.response.data[0].errorMessage : this.$t('common.somethingWentWrong');
            this.$notify({
              message: errorMsg,
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
        }
      }
    },
    validateInput() {
      let success = true;
      if (this.toolType === '') {
        this.$refs.toolTypeInput.focus();
        this.$notify({
          message: this.$t('errors.newToolTypeEmpty'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
          timeout: 2500,
        });
        success = false;
      }
      
      return success;
    }
  }
};
</script>
<style></style>
