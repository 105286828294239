<template>
<div class="row">
    <div class="col-md-4">
    <card>
      <h2>{{ toolGroup.name }}</h2>
      <img v-if="toolGroup.toolGroupImageBytes" class="toolImage" :src="'data:image/png;base64,' + toolGroup.toolGroupImageBytes" alt="tool alt text" />
      <h3 v-if="this.licencePlate">{{ this.licencePlate }}</h3>
      <h4 v-if="this.reservedBy"><b>{{this.$t('cars.reservedBy')}}:</b> {{ this.reservedBy }}</h4>
      <!-- <h4 v-if="this.lastService"><b>{{this.$t('cars.lastService')}}:</b> {{ this.lastService }}</h4>
      <h4 v-if="this.comment"><b>{{this.$t('cars.comment')}}:</b> {{ this.comment }}</h4> -->
      <div class="row">
        <div v-if="this.userRole !== 'User'" class="col-md-12">
          <base-dropdown
            direction="up"
            :menuOnRight=true
            title-classes="dropdown-toggle btn btn-standard btn-block"
            :title="$t('common.actions')"
          >
            <a v-if="!this.isMobile" @click="printQRCode" class="dropdown-item black">
              <i class="tim-icons icon-cloud-download-93 black"></i>
              {{ $t('tools.printQR') }}
            </a>
            <a @click="openLastServiceModal" class="dropdown-item black">
              <i class="tim-icons icon-pencil black"></i>
              {{ $t('cars.setServiceDetails') }}
            </a>
            <a @click="openServiceHistoryModal" class="dropdown-item black">
              <i class="tim-icons icon-notes black"></i>
              {{ $t('cars.openServiceHistory') }}
            </a>
            <a v-if="this.reservedBy" @click="unreserve" class="dropdown-item red">
              <i class="tim-icons icon-simple-remove red"></i>
              {{$t('cars.unreserve')}}
            </a>
            <a v-if="!this.reservedBy && !showReserveTable" @click="openReserveUserTable" class="dropdown-item green">
              <i class="tim-icons icon-single-02 green"></i>
              {{ $t('cars.assignToUser') }}
            </a>
          </base-dropdown>
        </div>
      </div>
    </card>
    <modal :show.sync="showLastServiceModal" class="white-content">
      <h3 class="card-title">{{$t('cars.setServiceDetails')}}</h3>
      <div class="row">
        <div class="col-12">
          <label class="col-form-label">{{$t('cars.lastService')}}</label>
          <base-input>
            <el-date-picker
              type="date"
              :placeholder="$t('cars.lastService')"
              v-model="lastService"
            >
            </el-date-picker>
          </base-input>
          <base-input
          :label="$t('cars.comment')"
          :placeholder="$t('cars.comment')"
          v-model="comment"
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm d-flex justify-content-end">
          <base-button @click="saveLastServiceDetails" type="standard" fill>{{$t('common.submit')}}</base-button>
        </div>
      </div>
    </modal>

    <modal :show.sync="showServiceHistoryModal" class="white-content">
      <card :title="$t('cars.serviceHistory')" :onCustomMethod="closeServiceHistoryModal" :customMethodIcon="'tim-icons icon-simple-remove'">
        <div class="row">
          <el-table :data="serviceHistory">
            <el-table-column
              v-for="column in serviceHistoryColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              v-if="column.vIf ? column.vIf() : true"
            >
            </el-table-column>
          </el-table>

        </div>
      </card>
    </modal>

    <div class="row">
      <user-reserve-table :toolGroupId="this.toolGroupId"  v-if="showReserveTable" @close="closeReserveUserTable" @refreshData="fetchData"></user-reserve-table>
    </div>
    </div>
    <div class="col-md-8">
      <add-tool-table :toolGroupId="this.toolGroupId" v-if="showAddToolTable"  @close="closeAddToolTable" @refreshData="fetchData"></add-tool-table>
      <card card-body-classes="table-full-width" :title="$t('cars.toolsInCar')" :onAddObject="this.userRole !== 'User' ? openAddToolTable : null">
        <div>
          <!-- Desktop Table -->
          <el-table v-if="!isMobile" :data="tools" :row-class-name="tableRowClassName">
            <el-table-column
              prop="toolImageThumbnailBytes"
              :label="$t('common.image')"
              width="80"
            >
              <template slot-scope="scope">
                <img :src="'data:image/png;base64,' + scope.row.toolImageThumbnailBytes" alt="Tool Image" />
              </template>
            </el-table-column>
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              v-if="column.vIf ? column.vIf() : true"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row[column.prop] }}
                    <br />
                    <a v-if="column.prop === 'name' && scope.row.toolReservations[0]" @click="navigateToUserProfile(scope.row.toolReservations[0].user.id)" class="clickable-link">
                      {{ scope.row.toolReservations[0].user.firstName}}
                    </a>
                </div>
              </template>
            </el-table-column>

            <el-table-column v-if="this.userRole !== 'User'" :min-width="135" align="right" label="">
              <div slot-scope="props">
                <base-button
                  @click.native.stop="removeTool(props.row.id)"
                  class="remove btn-link"
                  :class="{ 'black-button': props.row.toolReservations.length > 0 }"
                  type="danger"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
          <!-- Mobile Table -->
          <el-table v-else :data="tools" :row-class-name="tableRowClassName">
            <el-table-column :label="$t('common.image')">
              <template slot-scope="scope">
                <img :src="'data:image/png;base64,' + scope.row.toolImageThumbnailBytes" alt="Tool Image" />
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.info')" min-width="190">
              <template slot-scope="scope">
                <div>
                  <div><strong>{{$t('common.customerToolId')}}:</strong> {{ scope.row.customerToolId }}</div>
                  <div><strong>{{$t('common.name')}}:</strong> {{ scope.row.name }}</div>
                  <div><strong>{{$t('tools.brand')}}:</strong> {{ scope.row.brandName }}</div>
                  <div v-if="scope.row.toolReservations[0]">
                    <strong>{{$t('tools.reservedBy')}}:</strong>
                    <a @click="navigateToUserProfile(scope.row.toolReservations[0].user.id)" class="clickable-link">
                      {{ scope.row.toolReservations[0].user.firstName}}
                    </a>
                  </div>

                  <div v-if="userRole !== 'User'">
                    <base-button
                      @click.native.stop="removeTool(scope.row.id)"
                      class="remove btn-link"
                      :class="{ 'black-button': scope.row.toolReservations.length > 0 }"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="row" v-show="false">
          <card type="toolqr">
            <p class="card-text"></p>
            <div class="qr-print-container">
              <div >
                <img src=img/sidebar-header-tolspor2.png alt="app-logo" class="print-logo"/>
              </div>
              <qrcode-vue
                render-as="svg"
                :value="qrValue"
                :size="qrPrintSize"
                level="H"
                background="#ffffff"
                foreground="#000000"
                ref="printqrcode"
              >
              </qrcode-vue>
              <h3>{{ this.toolGroup.name }}</h3>
            </div>
          </card>
        </div>

      </card>
    </div>
</div>
</template>

<script>
import api from "../../services/api";  // Import your API service
import AddToolTable from "./AddToolTable";
import UserReserveTable from "./UserReserveTable";
import { Table, TableColumn, Select, Option,DatePicker, Row } from 'element-ui';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import swal from 'sweetalert2';
import config from "@/config";
import QrcodeVue from 'qrcode.vue';  // Import QrcodeVue from qrcode.vue
import { Modal } from 'src/components';
import ElBaseTable from '../../components/ElBaseTable.vue';




export default {
components: {
    QrcodeVue,
    AddToolTable,
    UserReserveTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    'el-base-table': ElBaseTable,

    Modal
},
data() {
    return {
    qrDisplaySize: 128,
    qrPrintSize: 256,
    toolGroup: {},
    tools:[],
    serviceHistory:[],
    showAddToolTable: false,
    showReserveTable: false,
    toolGroupId: 0,
    reservedBy: '',
    licencePlate:'',
    lastService: '',
    comment: '',
    showLastServiceModal: false,
    showServiceHistoryModal: false,
    serviceHistoryColumns: [
        { prop: 'date', label: this.$t('common.date'), minWidth: 120 },
        { prop: 'comment', label: this.$t('cars.comment'), minWidth: 200 },
      ],
    tableColumns: [
      {
        prop: 'customerToolId',
        label: this.$t('common.customerToolId'),
        minWidth: 60
      },
      {
        prop: 'name',
        label: this.$t('common.name'),
        minWidth: 150
      },
      {
        prop: 'description',
        label: this.$t('common.description'),
        minWidth: 240
      },
      {
        prop: 'brandName',
        label: this.$t('tools.brand'),
        minWidth: 150
      }
    ],
      isMobile: window.innerWidth <= 768,
      userRole: '',
  };
},
created() {
  this.toolGroupId = this.$route.params.toolGroupId
  const token = Cookies.get('accessToken');
  const decodedToken = jwtDecode(token);
  this.userRole = decodedToken.role
  this.fetchData();
},
computed: {
  qrValue() {
    // Combine the base URL with the tool ID to create the full URL
    let qrUrl = `${config.baseUrl}/#/car/${this.toolGroup.id}`;
    return qrUrl;
  },
},
methods: {
    saveLastServiceDetails(){
      let requestBody = {
        properties: [{key:'carService' + this.generateGUID(), value: this.lastService + '|' + this.comment}]
      };
      const response = api.put(`/ToolGroup/${this.toolGroupId}`, requestBody)
        .then(response =>
        {
          this.$notify({
            message: this.$t('common.updated'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
            timeout: 1500,
          });
          this.showLastServiceModal = false;
          // Adding a new item to the array
          let newDate = new Date(this.lastService).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
          this.serviceHistory.push({ date: newDate, comment: this.comment });
          this.serviceHistory.sort((a, b) => this.parseDate(b.date) - this.parseDate(a.date));

          this.comment = ''
          this.lastService = ''
        }).catch(error =>
        {
          console.error('API request error:', error);
          this.$notify({
            message: this.$t('common.unableToUpdate'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0,
          });
          this.showLastServiceModal = false;
        });
    },
    openLastServiceModal(){
      this.showLastServiceModal = true
    },
    openServiceHistoryModal(){
      this.showServiceHistoryModal = true;
    },
    closeServiceHistoryModal(){
      this.showServiceHistoryModal = false;
    },
    printQRCode() {
      var tmp = document.createDocumentFragment(),
      printme = document.getElementsByClassName('qr-print-container')[0].cloneNode(true);
      while(document.body.firstChild) {
          // move elements into the temporary space
          tmp.appendChild(document.body.firstChild);
      }
      // put the cloned printable thing back, and print
      document.body.appendChild(printme);
      window.print();

      while(document.body.firstChild) {
          // empty the body again (remove the clone)
          document.body.removeChild(document.body.firstChild);
      }
      // re-add the temporary fragment back into the page, restoring initial state
      document.body.appendChild(tmp);
    },
    tableRowClassName({ row }) {
      if (row.toolReservations && row.toolReservations.length > 0) {
        return 'red-row'; // This is the name of the CSS class you want to apply
      }
      return '';
    },
    parseDate(dateString){
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day); // month is zero-indexed
    },
    fetchData()
    {
      this.tools = [];
      let toolGroupUrl = `/ToolGroup/${this.toolGroupId}`;
      api.get(toolGroupUrl)
          .then(response => {
            this.toolGroup = response.data;
            if(this.toolGroup.toolToToolGroups && this.toolGroup.toolToToolGroups.length > 0){
              this.tools = this.toolGroup.toolToToolGroups.map(item => {
                return {
                  ...item.tool,
                  customerToolId: `#${item.tool.customerToolId}`
                };
              });
            }

            if(this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true) && this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true).length > 0)
            {
                this.reservedBy = this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true)[0].user.firstName + ' ' + this.toolGroup.toolGroupReservations.filter(p => p.isReserved === true)[0].user.lastName
            }
            else{
              this.reservedBy = ''
            }
            if(this.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate'))
            {
              this.licencePlate = this.toolGroup.toolGroupProperties.filter(p => p.key === 'licencePlate')[0].value
            }

            if(this.toolGroup.toolGroupProperties.filter(p => p.key.startsWith('carService'))
            && this.toolGroup.toolGroupProperties.filter(p => p.key.startsWith('carService')).length > 0)
            {
              this.serviceHistory = this.toolGroup.toolGroupProperties
                .filter(p => p.key.startsWith('carService'))
                .map(prop => {
                  const [date, comment] = prop.value.split("|");
                  return { date, comment };
                })
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort before formatting
                .map(item => {
                  // Format date to DD/MM/YYYY
                  const formattedDate = new Date(item.date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  });
                  return { date: formattedDate, comment: item.comment };
                });


            }
          })
          .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToRetrieveData'),
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
              timeout: 0,
            });
          });
    },
    generateGUID() {
      // Helper function to generate a random GUID
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    async removeTool(id)
    {
        swal.fire({
        title: this.$t('cars.areYouSureRemove'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('cars.yesRemoveIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put('/ToolGroup/RemoveTool', {
            toolGroupId: this.toolGroupId,
            toolId: id
          }).then(response => {
            this.fetchData();
            swal.fire({
              title: this.$t('cars.removedFromCar'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill',
              },
              buttonsStyling: false
            });
          })
          .catch(error => {
              console.error('API request error:', error);
              this.$notify({
                message: this.$t('common.unableToUpdate'),
                  icon: "tim-icons icon-alert-circle-exc",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                  timeout: 0,
              });
            });
        }
      });
    },
    async unreserve()
    {
      swal.fire({
        title: this.$t('cars.areYouSureReturn'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: this.$t('cars.yesReturnIt'),
        cancelButtonText: this.$t('delete.cancel'),
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          api.put(`/ToolGroup/Unreserve/${this.toolGroupId}`).then(response => {
          this.fetchData();
          swal.fire({
            title: this.$t('cars.returned'),
            text: `${this.$t('cars.youReturned')} ${this.toolGroup.name}`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
            },
            buttonsStyling: false
          });
        })
        .catch(error => {
            console.error('API request error:', error);
            this.$notify({
              message: this.$t('common.unableToUpdate'),
                icon: "tim-icons icon-alert-circle-exc",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
                timeout: 0,
            });
          });
        }
      });
    },
    navigateToUserProfile(userId) {
      this.$router.push({ name: 'User', params: { userId: userId } });
    },
    openReserveUserTable()
    {
    this.showReserveTable = true;
    },
    closeReserveUserTable()
    {
    this.showReserveTable = false;
    },
    openAddToolTable()
    {
    this.showAddToolTable = true;
    },
    closeAddToolTable()
    {
    this.showAddToolTable = false;
    },

},
};
</script>
<style scoped>
.red-row {
  background-color: rgb(214, 123, 123)!important;
}
.black-button {
  color: black !important;
}
.clickable-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.clickable-link:hover {
  color: #0056b3;
}
.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media print {
  h3 {
    color: black !important; /* Force the text color to be black when printed */
    font-weight: bold !important; /* Force the font weight to be bold when printed */
  }
  .qr-print-container {
    width: 100%;
    height: 50vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .qrcode-vue {
    width: 100%;
    height: auto;
  }
  .print-logo
  {
    width: 256px;
    padding-bottom: 10px;
  }
}
  .black {
  color: black;
  }

  .blue {
    color: blue;
  }

  .orange {
    color: orange;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
</style>
