<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="col-md-12">
					<tool-type-form v-if="showToolTypeForm"
						@close="closeToolTypeForm"
						@refreshData="fetchToolTypeData"
						:editMode="this.editMode"
						:toolTypeProp="this.type"
						:toolTypeId="this.toolTypeId"
					></tool-type-form>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<card :title="$t('toolTypes.toolTypes')" :onAddObject="openToolTypeForm">
					<div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
						<base-input>
							<el-input
								class="mb-3 search-input"
								clearable
								prefix-icon="el-icon-search"
								:placeholder="$t('common.searchRecords')"
								v-model="searchQuery"
								aria-controls="datatables"
								@keyup.native="search"
								@clear="handleClearSearch"
							>
							</el-input>
						</base-input>
					</div>
					<div v-if="tableDataReady">
						<div class="table-responsive">
							<el-base-table
								:tableData="tableData.data"
								:tableColumns="tableData.columns"
								:actionsInline="true"
								:enableEdit="true"
								@edit="handleToolTypeEdit"
							/>
						</div>
					</div>
				</card>
			</div>
		</div>
	</div>
</template>
<script>
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import api from "../../services/api";  // Import your API service
import ElBaseTable from "../../components/ElBaseTable.vue";
import { getCustomerConfig } from '../Settings/customerConfigHelper';
import ToolTypeForm from "./ToolTypeForm";


export default {
	components: {
		ToolTypeForm,
    'el-base-table': ElBaseTable
	},
	data() {
		return {
			pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      isMobile: window.innerWidth <= 768,
			tableData: {},
      tableDataReady: false,
			searchQuery: '',
			type: '',
			toolTypeId: 0,
			showToolTypeForm: false,
			editMode: false
		}
	},
	computed: {
		pagination_to() {
      let highBound = this.pagination_from + this.pagination.perPage;
      if (this.pagination_total < highBound) {
        highBound = this.pagination_total;
      }
      return highBound;
    },
    pagination_from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    pagination_total() {
      return this.pagination.total;
    },
	},
	created() {
    window.addEventListener('resize', this.updateIsMobile);
		const token = Cookies.get('accessToken');
    const decodedToken = jwtDecode(token);
    this.userRole = decodedToken.role;
    getCustomerConfig('DefaultPageSize')
    .then(config => {
      if(config === '') {
        this.pagination.perPage = 10
      }
      else {
        this.pagination.perPage = config
      }
      this.fetchToolTypeData();  // Fetch the table data
    })
	},
	methods: {
		updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
		async fetchToolTypeData() {
			this.tableData = {
        columns: [
          { prop: 'type', label: this.$t('toolTypes.toolType'), minWidth: 120, showOnMobile: true }
        ],
        data: [],
      }

			try {
				let toolTypeUrl = `/ToolType?type=${this.searchQuery}`
        const response = await api.get(toolTypeUrl);
				console.log(response);
        // this.pagination.total = response.data.totalItems;
				this.tableData.data = response.data;
				this.tableDataReady = true;
			} catch (error) {
        console.error('API request error:', error);
        this.$notify({
          message: this.$t('common.unableToRetrieveData'),
          icon: "tim-icons icon-alert-circle-exc",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0,
        });
      }
		},
		handleClearSearch() {
			this.fetchToolTypeData();
		},
		search(event) {
			this.pagination.currentPage = 1;
        const queryLength = this.searchQuery.length;
        if (event.key === 'Backspace' || event.key === 'Enter') {
          this.fetchToolTypeData();
        }
        else {
          if (queryLength > 1) {
            if (queryLength % 2 === 0) {
              this.fetchToolTypeData();
            }
          }
        }
		},
		handleToolTypeEdit(index, row) {
			this.editMode = true;
			this.type = row.type;
			this.toolTypeId = row.id;
			this.openToolTypeForm();
		},
		openToolTypeForm() {
			this.showToolTypeForm = true;
			this.$nextTick(() => {
				window.scrollTo(0, 0);
			});
  	},
		closeToolTypeForm() {
      this.showToolTypeForm = false;
      this.type = '';
      this.toolTypeId = 0;
      this.editMode = false;
  	},
	},
	beforeDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
  },
};
</script>
<style>
</style>

